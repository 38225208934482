import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Avatar,
  Typography
} from '@material-ui/core';

import { getInitials } from 'helpers';


const useStyles = makeStyles(theme => ({
  root: {
    margin: '.4rem'
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const TeamAvatar = props => {
  const { className, team, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h1">
              {team.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1">
              {team.description}
            </Typography>
          </div>
            <Avatar
                className={classes.avatar}
                src={team.avatarUrl}>
                {getInitials(team.name)}
            </Avatar>
        </div>
      </CardContent>
    </Card>
  );
};

TeamAvatar.propTypes = {
  className: PropTypes.string,
  team: PropTypes.any
};

export default TeamAvatar;
