import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const BeepTestLevelTurnsInput = props => {

    const ValidationTextField = withStyles({
        root: {
            '& input:valid + fieldset': {
                borderColor: 'green',
                borderWidth: 2,
            },
            '& input:invalid + fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
            '& input:valid:focus + fieldset': {
                borderLeftWidth: 6,
                padding: '4px !important',
            },
        },
    })(TextField);

    const referenceMap = {
        8: 11,
        9: 22,
        10: 33,
        11: 45,
        12: 57,
        13: 70,
        14: 83,
        15: 96,
        16: 110,
        17: 124,
        18: 139,
        19: 154,
        20: 170
    }

    const UpdateValue = (value) => {
        value = value.replace(',','.')
                     .replace(':','.');
        const normalizedValue = NormalizeValue(value);
        props.updateValue(props.userId, props.occasionId, props.instructionId, value, normalizedValue);
    };

    const NormalizeValue = (value) => {
        if (props.instruction.valueFormatRegex) {
          if (!RegExp(props.instruction.valueFormatRegex).test(value)) {
            return null;
          }
        }
        // Format should now be xx.yy where xx is level and yy is rounds.
        const splitValues = value.split('.');
        const level = parseInt(splitValues[0]);
        const rounds = parseInt(splitValues[1]);

        return referenceMap[level] + rounds;
    };

    const FormatValue = (value) => {
        const intValue = parseInt(value);
        for (let i = 7; i <= 20; i++){
            if (referenceMap[i+1] >= intValue)
            {
                return i.toString() + '.' + (intValue - referenceMap[i]).toString();
            }
        }

        return value;
    }

    if (props.excel) {
        if (props.statistic) {
            return (
                <input value={FormatValue(props.value) ?? ""} disabled={true}></input>
            );
        }

        return (
            <input type="text"
                value={props.value ?? ""}
                onChange={(e) => UpdateValue(e.target.value)}
                onClick={(e) => e.target.select()}
                disabled={props.readOnly}
                tabIndex={props.tabIndex}
                onBlur={() => props.saveSingleResult(props.userId, props.occasionId, props.instructionId)}></input>
        );
    }
    return (
        <ValidationTextField
            required
            id="validation-outlined-input"
            variant="outlined"
            InputProps={{
                endAdornment: <InputAdornment position="end">Min:Sec</InputAdornment>
            }} s
            size="small"
            onChange={(e) => UpdateValue(e.target.value)}
            value={props.value} />
    );
}

export default BeepTestLevelTurnsInput;