import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
import {setReturnPath} from '../../helpers'

const RouteWithLayout = props => {
  const { 
    layout: Layout, 
    component: Component, 
    requiresAuthentication: RequiresAuthentication, ...rest } = props;
  
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {  
    if (props.location){
      setReturnPath(props.location.pathname)
    }
  }, [props.location]);

  if(props.requiresAuthentication) {
    if(isAuthenticated)
    {  
      return (
        <Route
          {...rest}
          render={matchProps => (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          )} />
      );
    }
    else {
      loginWithRedirect();
    }
  }

  return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  requiresAuthentication: PropTypes.bool.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;