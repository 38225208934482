import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
// Testelope
import { LanguageContext } from 'context';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },  
  flexGrow: {
    flexGrow: 1
  },
  loginButton: {
    color: '#ffffff'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();

  const {
    loginWithRedirect,
  } = useAuth0();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed" >
      <Toolbar>
        <img
          alt="Logo"
          src="/images/logos/testelope_logo.png"
          width="150px"
        />
      <div className={classes.flexGrow} />
        <Button 
          className={classes.loginButton} 
          startIcon={<VpnKeyRoundedIcon />} 
          onClick={() => loginWithRedirect()}>
            {strings.login}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;