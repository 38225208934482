import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// MaterialUI
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  GridList,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
// Testelope
import { getInitials } from 'helpers';
import { TotalPlayers, TestCompletion } from 'components'
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
     padding: theme.spacing(4)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  tags: {
    paddingTop: '1rem'
  },    
  formControlAges:{
    display: 'flex',
    flexGrow: 1,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    columnGap: '2rem',
    whiteSpace: 'nowrap'
  },
  formTextfields: {
    placeContent: 'space-between',
  },
  teamstats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '80%',
    height: 350,
  },
  formAvatar: {
    paddingBottom: '1rem',
  },
  input: {
    display: 'none'
  },
  editPictureIcon: {
    border: "1px solid #000"
  }
}));


const TeamAvatar = props => {
  const { className, team, teamMembers, totalTestCompletionProcentage, editTeam, ...rest } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [saveErrors, setSaveErrors] = useState(null);
  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [teamForm, setTeamForm] = useState({
    teamId: null,
    teamName: '',
    teamDescription: '',
    teamAgeGroup: '',
    teamStartingYear: '',
    teamAvatar: '',
    teamAvatarPreview: '',
    teamProfileId: null
  });

  const year = (new Date()).getFullYear();
  const ageGroupYears = Array.from(new Array(50), (val, index) => year - index);
  const startingYears = Array.from(new Array(15), (val, index) => index + year - 5);

  const handleDialogOpen = (team) => {
    setTeamForm({
      teamId: team.id,
      teamName: team.name,
      teamDescription: team.description,
      teamAgeGroup: team.tags.find(t => t.name === 'ageGroup') !== undefined ? team.tags.find(t => t.name === 'ageGroup').value : '',
      teamStartingYear: team.tags.find(t => t.name === 'startingYear') !== undefined ? team.tags.find(t => t.name === 'startingYear').value : '',
      teamAvatarPreview: team.picture,
      teamProfileId: null
    });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
  };

  const handleDialogSubmit = async (teamForm) => {
    const saveErrors = await editTeam(teamForm);
    if (saveErrors){
      setSaveErrors(saveErrors);
      return;
    }
    setSaveErrors(null);
    setDialogOpen(false);
  };

  const handleTeamFormChange = (event) => {
      const name = event.target.name;
      setTeamForm({
          ...teamForm,
          [name]: event.target.value,
      });
  };

  // TODO: Add DisplayName in API
  const getTagDisplayName = (tag) => {
    if(tag === 'ageGroup')
      return strings.ageGroup;
    if(tag === 'startingYear')
      return strings.startingYear;
    else
      return tag;
  };

  const handlePictureChange = async event => {
    event.preventDefault();
    let file = event.target.files[0];

    if(file) {
      const base64string = await convertFileToBase64(file)

      setTeamForm({
        ...teamForm,
        teamAvatar: base64string,
        teamAvatarPreview: base64string
      });
    }
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

  return (
    <div>
      <Grid container spacing={4} >
        <Grid item xs={6} >
              <Card
                {...rest}
                className={clsx(classes.root, className)} >
                <CardContent>
                  <div className={classes.details}>
                    <div>
                      <Typography
                        gutterBottom
                        variant="h2">
                        {team.name}
                      </Typography>
                      <Typography
                        className={classes.locationText}
                        color="textSecondary"
                        variant="body1">
                        {team.description}
                      </Typography>
                      <div className={classes.tags}>
                      {
                          team.tags.map((tag, index) => {
                          return (
                            <Typography
                              key={index}
                              className={classes.dateText}
                              color="textSecondary"
                              variant="body1">
                              <b>{getTagDisplayName(tag.name)}:</b> {tag.value}
                            </Typography>
                          );
                          })
                      }
                      </div>
                    </div>
                    <Avatar
                        className={classes.avatar}
                        src={team.picture}>
                        {getInitials(team.name)}
                    </Avatar>
                  </div>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    className={classes.uploadButton}
                    color="primary"
                    variant="text"
                    onClick={() => handleDialogOpen(team)}>
                    {strings.editTeam}
                  </Button>
                </CardActions>
                </Card>
        </Grid>
        <Grid item xs={6} >
          <div className={classes.teamstats}>
            <GridList cellHeight={150} className={classes.gridList} cols={1}>
              <TotalPlayers className={classes.paper} totalPlayers={teamMembers.length} />
              <TestCompletion className={classes.paper} totalTestCompletion={totalTestCompletionProcentage} />
            </GridList>
          </div>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{teamForm.teamName}</DialogTitle>
        <DialogContent>
            <div className={classes.formAvatar}>
                <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(event) => handlePictureChange(event)}/>
                <label htmlFor="icon-button-file">       
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        badgeContent={<SmallAvatar><AddCircleIcon style={{ color: '#000000' }}/></SmallAvatar>}>
                        <Avatar
                            src={teamForm.teamAvatarPreview}>
                            {getInitials(team.name)}
                        </Avatar>
                    </Badge>
                </label>
            </div>
            <DialogContentText>
                {strings.editTeamDescription}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={strings.teamName}
                type="name"
                fullWidth
                value={teamForm.teamName}
                inputProps={{
                    name: 'teamName',
                    id: 'age-native-simple',
                }}
                onChange={handleTeamFormChange} />
            <TextField
                margin="dense"
                id="description"
                label={strings.teamDescription}
                type="description"
                multiline
                fullWidth
                value={teamForm.teamDescription}
                inputProps={{
                    name: 'teamDescription',
                    id: 'age-native-simple',
                }}
                onChange={handleTeamFormChange} />
            <div className={classes.formControlAges}>
                <FormControl className={classes.formControl} style={{minWidth: '48%'}}>
                    <InputLabel htmlFor="age-native-simple">{strings.ageGroup}</InputLabel>
                    <Select
                        native
                        value={teamForm.teamAgeGroup}
                        onChange={handleTeamFormChange}
                        inputProps={{
                            name: 'teamAgeGroup',
                            id: 'age-native-simple',
                        }} >
                        <option aria-label="None" value="" />
                        {ageGroupYears.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                        })}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} style={{minWidth: '48%'}}>
                    <InputLabel htmlFor="age-native-simple">{strings.startingYear}</InputLabel>
                    <Select
                        native
                        value={teamForm.teamStartingYear}
                        onChange={handleTeamFormChange}
                        inputProps={{
                            name: 'teamStartingYear',
                            id: 'age-native-simple',
                        }} >
                        <option aria-label="None" value="" />
                        {startingYears.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                        })}
                    </Select>
                </FormControl>
            </div>
            <div>
              {saveErrors && JSON.stringify(saveErrors)}
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose} color="default">
              {strings.close}
            </Button>
            <Button onClick={() => handleDialogSubmit(teamForm)} color="primary">
              {strings.save}
            </Button>
        </DialogActions>
    </Dialog>
    </div>
      

  );
};

TeamAvatar.propTypes = {
  className: PropTypes.string,
  team: PropTypes.any
};

export default TeamAvatar;
