import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { default as TestStatsCard } from './TestStatsCard';
import { OverviewToolbar } from 'components'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(5),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: 500,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      maxWidth: '160px',
      minWidth: '160px',
      marginRight: '2rem',
      maxHeight: 660,
      marginTop: '-5rem'
    },
    panel:{
        flexGrow: 2
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    grid:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList:{
    },
    results: {
        width: '100%',
      },
    container: {
        maxHeight: 540,
      },
  }));

const TestOverview = props => {

    const { instructions, occasions, results, teamMembers, registerResults, statistics } = props;

    const classes = useStyles();

    const [termTabValue, setTermTabValue] = React.useState(0);
    const [searchFilter, setSerarchFilter] = React.useState('');

    const handleTermTab = (event, newTermTabValue) => {
        setTermTabValue(newTermTabValue);
    };

    const instructionName = (name) => {
        return name.trim();
    };

    const searchChange = event => {
        setSerarchFilter(event.target.value);
    };

    const filterResults = (list, column, searchWord) => {
        if (list !== undefined) {
            return list.filter((listItem) => {
                return listItem[column].toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
            });
        }
    };

    const GetResultValue = (userId, occasionId, instructionId) => {
        let value = results.find(result => result.userId === userId &&
            result.instructionId === instructionId &&
            result.occasionId === occasionId);

        if (value){
            return value.normalizedValue;
        }
        return null;
    }

    return (
        <div>

        <OverviewToolbar
            className=""
            searchChange={searchChange} 
            searchLabel=""
            handleSave={null}
            navigateToRegisterResults={registerResults}/>

        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={termTabValue}
                onChange={handleTermTab}
                className={classes.tabs}>
                <Tab label="Overview" />
                {instructions.map((instruction, index) => (
                    <Tab label={instructionName(instruction.name)} key={instruction.id} />
                ))}
            </Tabs>
            <TabPanel value={termTabValue} index={0} className={classes.panel}>
                <div className={classes.grid}>
                    <GridList cellHeight={160} className={classes.gridList} cols={3}>
                        {filterResults(instructions, 'name', searchFilter).map((instruction, index) => (
                            <TestStatsCard instruction={instruction} statistics={statistics.filter(s => s.instructionId === instruction.id)} occasions={occasions} key={instruction.id}></TestStatsCard>
                        ))}
                    </GridList>
                </div>
            </TabPanel>
            {instructions.map((instruction, index) => (
                <TabPanel value={termTabValue} index={index+1} className={classes.panel}>
                <Card>
                    <CardContent>
                        <div className={classes.details}>
                            <Typography gutterBottom variant="h3">{instruction.name}</Typography>
                            <Typography className={classes.locationText} color="textSecondary" variant="body1"> {'Measured in ' + instruction.unit} </Typography>
                        </div>
                    </CardContent>
                </Card>
                    <Paper className={classes.results}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                {occasions.map((occasion) => (
                                    <TableCell
                                    key={occasion.id}
                                    align='right'>
                                    {occasion.name}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterResults(teamMembers, 'firstName', searchFilter).map((user) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                                        <TableCell>
                                            {user.firstName + ' ' + user.lastName}
                                        </TableCell>
                                    {occasions.map((occasion) => {
                                        let result = GetResultValue(user.id, occasion.id, instruction.id);
                                        return (
                                        <TableCell key={occasion.id} align='right'>
                                            {result}
                                        </TableCell>
                                        );
                                    })}
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </Paper>
                </TabPanel>
            ))}
        </div>
    </div>
    )
}

export default TestOverview;



