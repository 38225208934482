import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// Testelope
import { LanguageContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  saveBtn:{
    marginLeft: 'auto',
    marginRight: '1rem',
    marginBottom: '1rem'
  },
  language: {
    width: '100%'
  },
  formControl:{
    width: '100%'
  }
}));

const AccountSettings = props => {
  const { className, profile, updateCurrentProfile, ...rest } = props;
  const { strings, userLanguageChange } = useContext(LanguageContext);
  const classes = useStyles();

  const [language, setLanguage] = useState('');

  useEffect(() => {
    setLanguage(strings.getLanguage())
}, []);

  const handleLangChange = event => {
    setLanguage(event.target.value);
    userLanguageChange(event.target.value);

    window.location.reload(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <form
        className={classes.root}
        autoComplete="off"
        noValidate >
        <CardHeader
          subheader={strings.userSettingsInfo}
          title={strings.userSettings} />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}>
            <Grid item md={6} xs={12} >
              <div className={classes.language}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">{strings.language}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    onChange={handleLangChange}>
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"sv"}>Svenska</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

AccountSettings.propTypes = {
  className: PropTypes.string
};

export default AccountSettings;
