import React, { useContext, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Button, colors } from '@material-ui/core';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import DuoRoundedIcon from '@material-ui/icons/DuoRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import { Profile, SidebarNav } from './components';
import { LanguageContext } from 'context';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 64,
      height: 'calc(100%)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  closeSidebar: {
    position: 'Absolute',
    bottom: '1rem',
    width: '205px'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, forceSidebarClose, setForceSidebarClose, ...rest } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();

  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
  logout({
    returnTo: window.location.origin,
  });

  const pages = [
    {
      title: strings.home,
      href: '/overview',
      icon: <DashboardRoundedIcon style={{ color: '#4e89ae' }} />
    },
    {
      title: strings.myTeams,
      href: '/teams',
      icon: <PeopleRoundedIcon  style={{ color: '#4e89ae' }}   />
    },
    {
      title: strings.myAccount,
      href: '/profile',
      icon: <PersonRoundedIcon style={{ color: '#4e89ae' }} />
    }
  ];

  const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant} >
      <div
        {...rest}
        className={clsx(classes.root, className)}>  
        {isAuthenticated === true ? (
          <div>
            <Profile />
            <Divider className={classes.divider} />
          </div>
        ) : null }
        <SidebarNav
          className={classes.nav}
          pages={pages} />
        <Divider className={classes.divider}></Divider>
        {isAuthenticated === true ? (
          <div>
             <Button
               className={classes.button}
               component={CustomRouterLink}
               to={"/help"}>
               <div className={classes.icon}>{<DuoRoundedIcon style={{ color: '#4e89ae' }} />}</div>
              {strings.help}
             </Button>
             <Divider className={classes.divider}></Divider>
             <Button
               className={classes.button}
               onClick={() => logoutWithRedirect()}>
               <div className={classes.icon}>{<LockRoundedIcon style={{ color: '#4e89ae' }} />}</div>
               {strings.logout}
             </Button>
            
             <div className={classes.closeSidebar}>
             <Divider className={classes.divider}></Divider>
             {forceSidebarClose !== true ? (
             <Button
               className={classes.button}
               onClick={() => setForceSidebarClose(true)}>
               <div className={classes.icon}>{<HighlightOffRoundedIcon style={{ color: '#ed6663' }} />}</div>
               {strings.closeMenu}
             </Button> ): 
              <Button
              className={classes.button}
              onClick={() => setForceSidebarClose(false)}>
              <div className={classes.icon}>{<MenuOpenRoundedIcon style={{ color: '#4e89ae' }} />}</div>
              {strings.openMenu}
              </Button> }
              </div>
            </div>
           ) : <Button color="inherit" onClick={() => loginWithRedirect()}>Login</Button>  }
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
