import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { OverviewTable, OccasionOverviewTable, UserResultsOverview, TeamStatsComparison } from './components';
import { Button, Dialog, DialogActions, DialogContent, Container } from '@material-ui/core';
import { LanguageContext } from 'context';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    content: {
        marginTop: theme.spacing(2)
    },
    breadcrumbs: {
        margin: '1rem'
    },
    tile: {
        height: 'fit-content !Important',
        marginBottom: '2rem'
    },
    tab: {
        minWidth: '100px'
    },
    printIframe: {
        display: 'none'
    },
    userResultsOverview: {
        width: '1000px'
    }
}));

const TeamDetailsExcelVersion = props => {
    const { strings } = useContext(LanguageContext);
    const classes = useStyles();
    const [termTabValue, setTermTabValue] = useState(0);
    const [userOverview, setUserOverview] = useState({
        user: {},
        instructions: {},
        results: {}
    });
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);

    const handleTermTab = (event, newTermTabValue) => {
        setTermTabValue(newTermTabValue);
    };

    const ShowUserOverview = (user) => {
        setUserOverview({
            user: user,
            results: props.results.filter(r => r.userId === user.id)
        });
        setShowUserInfoModal(true);
    };

    const print = () => {
        let userResultsOverviewDiv = document.getElementById('userResultsOverview');
        const printContent = userResultsOverviewDiv.innerHTML;

        let printMe = document.getElementById('printMe');
        printMe.contentWindow.document.open();
        printMe.contentWindow.document.write(printContent);
        printMe.contentWindow.document.close();

        printMe.contentWindow.document.head.innerHTML = document.head.innerHTML;
        printMe.focus();
        printMe.contentWindow.print();
    };

    const printElementHtml = (elementId) => {
        let element = document.getElementById(elementId);
        let canvasList = element.getElementsByTagName('canvas');
        for (let i = 0; i < canvasList.length; i++) {
            let canvasImg = document.createElement('Img');
            canvasImg.src = canvasList[i].toDataURL();
            canvasImg.style.pageBreakBefore = 'auto';
            canvasImg.style.pageBreakAfter = 'auto';
            canvasImg.style.pageBreakInside = 'avoid';
            canvasList[i].replaceWith(canvasImg);
        };

        const printContent = element.innerHTML;
        let printMe = document.getElementById('printMe');
        printMe.contentWindow.document.open();
        printMe.contentWindow.document.write(printContent);
        printMe.contentWindow.document.close();

        printMe.contentWindow.document.head.innerHTML = document.head.innerHTML;
        printMe.focus();
        setTimeout(() => {
            printMe.contentWindow.print();
        }, 0);
    }

    const close = () => {
        setShowUserInfoModal(false);
    }

    return (<>
        <div className={classes.root}>
            <Paper className={classes.root}>
                <Tabs
                    value={termTabValue}
                    onChange={handleTermTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered >
                    <Tab label={strings.overview} className={classes.tab} />
                    {props.occasions.map((occasion, index) => (
                        <Tab label={occasion.name} key={occasion.id} className={classes.tab} />
                    ))}
                    <Tab label={strings.comparison} className={classes.tab} />
                </Tabs>
                <TabPanel value={termTabValue} index={0}>
                    <OverviewTable occasions={props.occasions}
                        instructions={props.instructions}
                        results={props.results}
                        teamMembers={props.teamMembers}
                        teamStatistics={props.teamStatistics}
                        isLoading={props.isLoading}
                        showUserOverview={ShowUserOverview}
                        printElementHtml={printElementHtml}></OverviewTable>
                </TabPanel>
                {props.occasions.map((occasion, index) => (
                    <TabPanel key={occasion.id} value={termTabValue} index={index + 1}>
                        <OccasionOverviewTable instructions={props.instructions.filter(i => occasion.instructionIds.includes(i.id))}
                            results={props.results}
                            teamMembers={props.teamMembers}
                            teamStatistics={props.teamStatistics}
                            occasion={occasion}
                            setResultValue={props.setResults}
                            saveAllResults={props.saveAllResults}
                            saveSingleResult={props.saveSingleResult}>
                        </OccasionOverviewTable>
                    </TabPanel>
                ))}
                <TabPanel value={termTabValue} index={props.occasions.length + 1}>
                    <TeamStatsComparison 
                        teamStatistics={props.teamStatistics} 
                        profileStatistics={props.profileStatistics}
                        occasions={props.occasions}
                        instructions={props.instructions} 
                        profileId={props.profileId}
                        teamId={props.teamId}/>
                </TabPanel>
            </Paper>
        </div>
        <iframe title='print' src='about:blank' id='printMe' className={classes.printIframe}></iframe>
        <Dialog
            open={showUserInfoModal} maxWidth='lg'>
            <DialogContent>
                <Container>
                    <div id='userResultsOverview' >
                        <UserResultsOverview
                            user={userOverview.user}
                            results={userOverview.results}
                            instructions={props.instructions}
                            teamStatistics={props.teamStatistics}
                            occasions={props.occasions} />
                    </div>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={print} color="primary" variant="contained">{strings.print}</Button>
                <Button onClick={close} color="secondary" variant="contained">{strings.close}</Button>
            </DialogActions>
        </Dialog>
    </>
    );

};

export default TeamDetailsExcelVersion;
