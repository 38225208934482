import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material UI
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    searchForm:{
        flexGrow: 1,
        paddingLeft: '14rem',
        paddingTop: '3rem'
    },
    formControl: {
        minWidth: 160,
        marginRight: '3.5rem'
      },
      saveButton:{
          marginRight: '2rem'
      }
}));

const ResultsToolbar = props => {

    const { 
        className, 
        dropdownValue, 
        dropdownChange, 
        searchChange, 
        searchLabel,
        handleSave, 
        cbxPlayersResultChecked, 
        cbxPlayersResultChange, 
        cbxUncompletedTestsChecked,
        cbxUncompletedTestsChange,
        ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                {/* <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">Group by</InputLabel>
                        <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={dropdownValue}
                        onChange={dropdownChange}
                        label="Group by">
                            <MenuItem value={"Occasions"}>Occasions</MenuItem>
                            <MenuItem value={"Tests"}>Tests</MenuItem>
                        </Select>
                </FormControl> */}
                <div className={classes.searchForm}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder={"Search " + searchLabel}
                    onChange={searchChange}/>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={cbxPlayersResultChecked} onChange={cbxPlayersResultChange} name="cbx_playersResult" />}
                            label="Display only players without results"/>
                        <FormControlLabel
                            control={<Checkbox checked={cbxUncompletedTestsChecked} onChange={cbxUncompletedTestsChange} name="cbx_playersResult" />}
                            label="Display only uncompleted tests"/>
                    </FormGroup>
                </div>
                <span className={classes.spacer} />
                <div>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    onClick={handleSave}
                    variant="contained">Save all
                </Button>
                </div>
            </div>
        </div>
    );
};

ResultsToolbar.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default ResultsToolbar;
