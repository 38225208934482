import React from 'react';
import { Switch } from 'react-router-dom';
// Testelope
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Start as StartPage,
  Login as LoginPage,
  Verification as VerificationPage,
  Overview as OverviewPage,
  Teams as TeamsPage,
  TeamOverview as TeamOverviewPage,
  TeamDetailsExcelVersion,
  Help as HelpPage,
  Profile as ProfilePage,
  // Instructions as InstructionsPage,
  // InstructionDetails as InstructionDetailsPage,
  // Players as PlayersPage,
  // PlayerDetails as PlayerDetailsPage,
} from './Pages';

const Routes = () => {

  return (
    <Switch>
      <RouteWithLayout
        component={StartPage}
        exact
        layout={MinimalLayout}
        requiresAuthentication={false}
        path="/" /> 
      <RouteWithLayout
        component={LoginPage}
        exact
        layout={MinimalLayout}
        requiresAuthentication={false}
        path="/login" />
      <RouteWithLayout
        component={VerificationPage}
        exact
        layout={MinimalLayout}
        requiresAuthentication={false}
        path="/verify" />
      <RouteWithLayout
        component={OverviewPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/overview" />
      <RouteWithLayout
        component={TeamsPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/teams" />
      <RouteWithLayout
        component={TeamOverviewPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/teams/:teamId" />
      <RouteWithLayout
        component={TeamDetailsExcelVersion}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/teamsExcel/:teamId" />
      {/* <RouteWithLayout
        component={InstructionsPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/instructions" />
      <RouteWithLayout
        component={InstructionDetailsPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/instructions/:instructionId" /> */}
      {/* <RouteWithLayout
        component={PlayersPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/players" /> */}
      {/* <RouteWithLayout
        component={PlayerDetailsPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/players/:playerId" /> */}
      <RouteWithLayout
        component={ProfilePage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/profile" />
      <RouteWithLayout
        component={HelpPage}
        exact
        layout={MainLayout}
        requiresAuthentication={true}
        path="/help" />
    </Switch>
  );
};

export default Routes;
