import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {},
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
}));

const MovieCard = props => {
  const { className, title, movieUrl, movieType, ...rest } = props;

  const classes = useStyles();

  const download = (url) => {
    window.location.href = url;
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <CardContent>
        <video width="100%" controls preload="metadata">
            <source src={movieUrl} type={movieType} />
            Your browser does not support the video tag.
        </video>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid
          container
          justify="space-between">
          <Grid
            className={classes.statsItem}
            item>
            <Typography
              display="inline"
              variant="subtitle2">
              {title}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item >
            <input accept="image/*" className={classes.input} onClick={() => download(movieUrl)} />
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                <GetAppIcon />
                </IconButton>
            </label>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

MovieCard.propTypes = {
  className: PropTypes.string,
  movieUrl: PropTypes.string.isRequired
};

export default MovieCard;
