import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '17rem',
    whiteSpace: 'nowrap'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadText: {
    alignSelf: 'center',
    paddingRight: '.4rem'
  },
  uploadButton: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '1rem',
  },
  input: {
    display: 'none',
  },
}));

const AccountProfile = props => {
  const { className, profile, ...rest } = props;
  
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              className={classes.name}
              gutterBottom
              variant="h2">
              {profile.displayName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1">
              {profile.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1">
              {profile.phoneNumber}
            </Typography>
          </div>
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={profile.avatar}
          />
        </div>
      </CardContent>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
