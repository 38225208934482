import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AccountCircle from '@material-ui/icons/AccountCircle';
// Testelope
import { LanguageContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  saveBtn:{
    marginLeft: 'auto',
    marginRight: '1rem',
    marginBottom: '1rem'
  },
  language: {
    minWidth: '150px'
  },
  uploadTextField: {
    width: '1000%',
    alignSelf: 'center',
    paddingRight: '.4rem'
  },
  uploadButton: {
    display: 'flex',
    marginLeft: 'auto',
  },
  input: {
    display: 'none',
  },
  uploadIcon:{
    marginBottom: '.3rem'
  }
}));

const AccountDetails = props => {
  const { className, profile, updateCurrentProfile, ...rest } = props;

  const { strings } = useContext(LanguageContext);
  const classes = useStyles();

  const [profileForm, setProfileForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    avatar: '',
  });

  const [picture, setPicture] = useState({
    file: '',
    base64String: ''
  });

  useEffect(() => {
    setProfileForm({
      firstName: profile.firstName,
      lastName: profile.lastName,
      phoneNumber: profile.phoneNumber,
      email: profile.email
    });
}, []);

  const handleChange = event => {
    setProfileForm({
      ...profileForm,
      [event.target.name]: event.target.value
    });
  };

  const handlePictureChange = async event => {
    event.preventDefault();
    let file = event.target.files[0];

    if(file) {
      setPicture({
        file: file
      });

      const base64string = await convertFileToBase64(file)

      setProfileForm({
        ...profileForm,
        avatar: base64string
      });
    }
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <form
        className={classes.root}
        autoComplete="off"
        noValidate >
        <CardHeader
          subheader={strings.userProfileInfo}
          title={strings.userProfile} />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label={strings.firstName}
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={profileForm.firstName}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label={strings.lastName}
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={profileForm.lastName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={strings.emailAddress}
                margin="dense"
                name="email"
                onChange={handleChange}
                disabled
                value={profileForm.email}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label={strings.phoneNumber}
                margin="dense"
                name="phoneNumber"
                onChange={handleChange}
                value={profileForm.phoneNumber}
              />
            </Grid>
            <Grid item md={6} xs={12} >
            <div className={classes.uploadButton}>
              <span className={classes.uploadTextField}>
              <TextField
                fullWidth
                helperText="Upload a new profile picture by clicking the camera icon"
                margin="dense"
                name="picture"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle className={classes.uploadIcon} />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                value={picture.file.name ?? ''}
              />
              </span>
              <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(event) => handlePictureChange(event)}/>
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
            </div>
            </Grid>
            <Grid item md={6} xs={12}></Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            className={classes.saveBtn}
            color="primary"
            variant="contained"
            onClick={() => updateCurrentProfile(profileForm)}>
            {strings.save}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
