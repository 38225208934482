import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
// Testelope
import { useApi } from 'hooks';
import {
  TotalTeams,
  TotalResults,
  LatestTeams,
  LatestTests
} from './components';
import { 
  TotalPlayers
 } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '1rem',
    paddingTop: '4rem'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title:{
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));

const Overview = props => {
  const { callApi } = useApi();
  const classes = useStyles();

  const [data, setData] = useState({ overview: {}, isFetching: false });

  const {
    user,
    isAuthenticated,
  } = useAuth0();

  useEffect(() => {
    if(isAuthenticated){
    }

    const fetchOverview = async () => {
      try {
          setData({ overview: data.overview, isFetching: true });
          
          const response = await callApi('/overview', 'get', null, null);
          setData({ overview: response, isFetching: false });
      } catch (e) {
          console.log(e);
          setData({ overview: data.overview, isFetching: false });
      }
    };
    fetchOverview();
  }, [isAuthenticated, user]);

  return (
    <div className={classes.root}>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TotalTeams className={classes.paper} totalTeams={data.overview.totalTeams} />
      </Grid>
      <Grid item xs={4}>
          <TotalPlayers className={classes.paper} totalPlayers={data.overview.totalPlayers} />
      </Grid>
      <Grid item xs={4}>
          <TotalResults className={classes.paper} totalResults={data.overview.totalResults} />
      </Grid>
      <Grid item xs={6}>
      <LatestTests className={classes.paper} latestTests={data.overview.latestTests} />
      </Grid>
      <Grid item xs={6}>
        <LatestTeams className={classes.paper} latestTeams={data.overview.latestTeams} />
      </Grid>
    </Grid>
  </div>
  );
};

export default Overview;
