
    import {
        ReadOnly,
        CountInput,
        FailPassInput,
        MinutesSecondsInput,
        CentimetersInput,
        KilosInput,
        TempInput,
        SecondsInput,
        MetersInput
    } from 'components';
    
    const InputComponentsMap = {
        Default: ReadOnly,
        Count: CountInput,
        Kilos: KilosInput,
        Meters: MetersInput,
        Temp: TempInput,
        Seconds: SecondsInput,
        Centimeters: CentimetersInput,
        FailPass: FailPassInput,
        MinutesSeconds: MinutesSecondsInput
    };

    export default InputComponentsMap;