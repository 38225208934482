import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const SecondsInput = props => {

  const ValidationTextField = withStyles({
    root: {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important',
      },
    },
  })(TextField);

  const UpdateValue = (value) => {
    value = ValidateInput(value);
    
    const normalizedValue = NormalizeValue(value);
    props.updateValue(props.userId, props.occasionId, props.instructionId, value, normalizedValue);
  };

  const ValidateInput = (value) => {
    if (value){
      value = value.replace(",", ".");

      const floatValue = parseFloat(value);
      if ((props.instruction.numberOfDecimals !== null) && !Number.isNaN(floatValue) && value.indexOf('.') > 0){
        value = value.substring(0, value.indexOf('.') + 1 + props.instruction.numberOfDecimals);
      }
      return value;
    }
  }

  const NormalizeValue = (value) => {
    return value ?? null;
  };

  const FormatValue = (value) => {
    return value && value.toLocaleString(undefined, { minimumFractionDigits: props.instruction.numberOfDecimals, maximumFractionDigits: props.instruction.numberOfDecimals });
  }

  if (props.excel) {
    if (props.statistic) {
      return (
        <input value={FormatValue(props.value) ?? ""} disabled={true}></input>
      );
    }
    
    return (
      <input type="text"
        value={props.value ?? ""}
        onChange={(e) => UpdateValue(e.target.value)}
        onClick={(e) => e.target.select()}
        disabled={props.readOnly}
        tabIndex={props.tabIndex}
        onBlur={ () => props.saveSingleResult(props.userId, props.occasionId, props.instructionId) }></input>
    );
  }
  return (
    <ValidationTextField
      required
      id="validation-outlined-input"
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="end">Seconds</InputAdornment>
      }}
      size="small"
      onChange={(e) => UpdateValue(e.target.value)}
      value={props.value ?? ""} />
  );
}

export default SecondsInput;