import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';

import { useApi } from 'hooks';
import InstructionNameCard from './components/InstructionNameCard';
import ResultsDistributionGraph from './components/ResultsDistributionGraph'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    breadcrumbs:{
        margin: '1rem'
    }
}));

const InstructionDetails = props => {

    const { callApi } = useApi();
    const classes = useStyles();
    const history = useHistory();

    const [data, setData] = useState({ instruction: {}, isFetching: false });
    const [searchFilter, setSerarchFilter] = useState('');
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    useEffect(() => {
        const fetchInstructionDetails = async () => {
            try {
                setData({ instruction: data.instruction, isFetching: true });
                const response = await callApi('/instructions/' + props.match.params.instructionId + "?_embed=results", 'get', null, null);
                setData({ instruction: response, isFetching: false });
            } catch (e) {
                console.log(e);
                setData({ instruction: data.instruction, isFetching: false });
            }
        };
        fetchInstructionDetails();
    }, []);

    const filterData = event => {
        setSerarchFilter(event.target.value);
    }

    const max = results => {
        var max = results.reduce((a, b) => {
            return Math.max(a, b.normalizedValue);
        }, 0);
        return max;
    }
    const min = results => {
        var min = results.reduce((a, b) => {
            return Math.min(a, b.normalizedValue);
        }, 100000000);
        return min;
    }
    const avg = results => {
        var sum = results.reduce((a, b) => {
            return a + b.normalizedValue;
        }, 0);
        return (sum / results.length).toFixed(2);
    }

    var normalDistributionData = (results, min, max, steps) => {
        let labels = [];
        let dataPoints = [];

        var stepSize = (max - min) / steps;

        for (let i = 0; i < steps; i++) {
            const start = min + stepSize * i;
            const end = start + stepSize;

            var resultsInRange = results.filter((result) => {
                return result.normalizedValue >= start &&
                    result.normalizedValue <= end;
            });

            if (resultsInRange.length > 0) {
                labels.push(start.toFixed(2) + '-' + end.toFixed(2));

                dataPoints.push(Math.round(resultsInRange.length / results.length * 100));
            }
        }

        return {
            labels: labels,
            datasets: [
                {
                    label: '% of results',
                    fill: false,
                    lineTension: 0.1,
                    // backgroundColor: 'rgba(75,192,192,0.4)',
                    // borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: dataPoints
                }
            ]
        };
    }

    if (data.isFetching || !data.instruction.results) {
        return "Loading..."
    }

    var minVal = min(data.instruction.results);
    var maxVal = max(data.instruction.results);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Link color="inherit" href="#" onClick={() => {
                    history.push('/instructions');
                }}>Instructions
                </Link>
                <Typography color="textPrimary">{data.instruction.name}</Typography>
            </Breadcrumbs>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered >
                    <Tab label="Overview" />
                    <Tab label="Players" />
                    <Tab label="Teams" />
                </Tabs>
                <TabPanel value={value} index={0}>

                    <Grid
                        container
                        spacing={4}>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}>
                            <InstructionNameCard
                                name={data.instruction.name}
                                description={data.instruction.description}
                                min={minVal}
                                max={maxVal}
                                avg={avg(data.instruction.results)}
                                resultCount={data.instruction.results.length} />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={12}>
                            <ResultsDistributionGraph dataSet={normalDistributionData(data.instruction.results, minVal, maxVal, 20)}>

                            </ResultsDistributionGraph>
                        </Grid>
                    </Grid>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    Players
                    {/* <Toolbar searchChange={filterData} tableName="players"/>
                    <div className={classes.content}>
                        <PlayersTable players={players.players} filter={searchFilter} />
                    </div> */}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Teams
                    {/* <Toolbar searchChange={filterData} tableName="instructions"/>
                    <div className={classes.content}>
                        <InstructionsTable instructions={instructions.instructions} filter={searchFilter} />
                    </div> */}
                </TabPanel>
            </Paper>
        </div>
    );
};

export default InstructionDetails;
