import React, { useContext } from 'react';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
// Testelope
import { MovieCard } from './components';
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '4rem',
    marginLeft: '2rem',
    marginRight: '2rem',
    padding: theme.spacing(2),
  },
  title:{
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));

const Help = () => {
  const classes = useStyles();
  const { strings } = useContext(LanguageContext);

  return (
    <div className={classes.root}>
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <div className={classes.title}>
          <Typography
              className={classes.header}
              variant="h1">
              {strings.howToVideos}
            </Typography>
        </div>
      </Grid>
        <Grid item xs={6}>
            <MovieCard title={strings.movieHowToCreateaNewTeam} movieUrl="https://testelopestorage.blob.core.windows.net/help/C_Create_team.mp4" movieType="video/mp4"/>
        </Grid>
        <Grid item xs={6}>
            <MovieCard title={strings.movieHowToAddPlayers} movieUrl="https://testelopestorage.blob.core.windows.net/help/C_Add_Players 1.mp4" movieType="video/mp4"/>
        </Grid>
        <Grid item xs={6}>
            <MovieCard title={strings.movieHowToRegisterResults} movieUrl="https://testelopestorage.blob.core.windows.net/help/C_Register_results.mp4" movieType="video/mp4"/>
        </Grid>
        <Grid item xs={6}>
            <MovieCard title={strings.movieHowToUseFilters} movieUrl="https://testelopestorage.blob.core.windows.net/help/C_How_to_use_filters.mp4" movieType="video/mp4"/>
        </Grid>
        <Grid item xs={6}>
            <MovieCard title={strings.movieHowToPrint} movieUrl="https://testelopestorage.blob.core.windows.net/help/C_Print_individual_tests.mp4" movieType="video/mp4"/>
        </Grid>
    </Grid>
  </div>
  );
};

export default Help;
