import Axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const useApi = () => {

    const { getAccessTokenSilently } = useAuth0();

    const callApi = async (endpoint, method, body, filter, useMockData) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const client = Axios.create({
                baseURL: '/api',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                timeout: 30000,
            });

            const paramData =
            {
                endpoint: endpoint,
                method: method,
                body: JSON.stringify(body),
                filter: filter,
                useMockData: useMockData === true || window.debug === "true"
            };

            return await client.post('/proxy', JSON.stringify(paramData))
            .then(response => {
                if (paramData.useMockData){
                    console.log(`Mock API called to get data for endpoint ${endpoint}`);
                }
                return Promise.resolve(response.data);
            }).catch(error => {
                const errorMessage = error.response.data.errorMessage ?? 'Unknown error';
                console.log('Error caught when calling API.', errorMessage, error);
                return Promise.reject(errorMessage);
            });
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return {
        callApi
    }
};

export default useApi;
