import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
    // Get-started
      qoute1: "Physical test registration and tracking",
      qoute2: "for sports made easy",
      welcomeTitle: "Welcome to Testelope",
      subtitle1: "Whether you're a coach, player, or even a sports federation, you've most likely experienced challenges with registering, tracking, and collecting physical test results.",
      subtitle2: "Testelope enables you to get tests registered, tracked, and aggregated into valuable information that will help you increase your understanding of how the results can be improved.",
      actionText: "Get started for free with no credit card required and start your effortless test management now!",
      getStarted: "Get started",
      login: "Login",
    // Verify
      verifyAccountTitle: "Activate your account",
      verifyAccountDescription: "An email has been sent to the email address you provided while creating your account. Follow the instructions in the email to activate your account. After your account has been verified you will be able to login.",
      verifyAccountEmailHint: "If you can't find the activation email, check your junk inbox or ",
      resendActivation: "resend activation email",
    // Dashboard
      welcome: "Welcome",
      totalTests: "Total tests",
      totalTeams: "Total teams",
      totalPlayers: "Total Players",
      testCompletion: "Total test completion",
      totalResults: "Totalt results registred",
      latestUpdatedTests: "Latest updated tests",
      latestUpdatedTeams: "Latest updated teams",
      viewAll: "View all",
      updated: "Updated",
    // Menu
      home: "Home",
      myTeams: "My teams",
      myAccount: "My account",
      help: "Help",
      logout: "Logout",
      closeMenu: "Hide menu",
      openMenu: "Keep open",
    // Account
      userProfile: "User profile",
      userProfileInfo: "Please update the profile with your information below",
      userSettings: "User settings",
      userSettingsInfo: "Customize your experience by changing the settings below",
      firstName: "First name",
      lastName: "Last name",
      emailAddress: "Email address",
      phoneNumber: "Phone number",
      save: "Save",
      language: "Language",
    // Help
      howToVideos: "How-to videos",
      movieHowToCreateaNewTeam: "How to create a new team",
      movieHowToAddPlayers: "How to add players to a team",
      movieHowToRegisterResults: "How to register test results",
      movieHowToUseFilters: "How to use filters",
      movieHowToPrint: "How to print induvidual results",
    // Teams
      search: "Search",
      team: "team",
      addNewTeam: "Add new team",
      addNewTeamDescription: "To create a new team, please fill in the fields below and press the Add button. You can edit team settings in the team overview later.",
      teamName: "Team name",
      teamDescription: "Description",
      ageGroup: "Age group",
      startingYear: "Starting year",
      year: "year",
      testProfile: "Test profile",
      clear: "Clear",
      close: "Close",
      add: "Add",
      teamExistsError: "A team with same name already exists",
      players: "players",
      managers: "managers",
      tests: "tests",
      hasBeenUpdated: "has been updated",
      haveBeenAdded: "have been added",
      editTeam: "Edit team",
      editTeamDescription: "To edit the team, please update the fields below and press the 'Save' button",
      noTeamsFound: "No teams found",
      noPlayersFound: "No players found",
      playerName: "Player name",
      email: "E-mail",
      editPlayerDescription: "To edit a player, please update the fields below and press the 'Save' button.",
      addNewPlayer: "Add new player",
      addNewPlayerDescription: "To create a new player, please fill in the fields below and press the 'Add' button. You can edit player settings in player details later.",
      print: "Print",
      overview: "Overview",
      filterTests: "Filter tests",
      filterPlayers: "Filter players",
      noValidValue: "Not valid value. Excluded from statistics.",
      notSaved: "Not saved.",
      max: "Max",
      min: "Min",
      median: "Median",
      average: "Average",
      pass: "Pass",
      fail: "Fail",
      hasBeenDeleted: "has been deleted",
      delete: "Delete",
      deletePlayerConfirmation: "Delete player?",
      deletePlayerConfirmationDesc: "Are you sure you want to delete player",
      edit: "Edit",
      player: "Player",
      manager: "Manager",
      deleteTeamConfirmation: "Delete team",
      deleteTeamConfirmationDesc: "Are you sure you want to delete team",
      rememberToSave: "Remember to save now and then...",
      name: "Name",
      searchManagerToAdd: "Search manager to add",
      createWithoutEmail: "Create without e-mail",
      comparison: "Comparison"
    },
    sv: {
    // Get-started
      qoute1: "Registrering och uppföljning av tester inom idrott",
      qoute2: "Lätt att använda, tungt värde",
      welcomeTitle: "Välkommen till Testelope",
      subtitle1: "Är du en tränare, spelare eller tillhör du ett idrottsförbund har du som många andra sannolikt utmaningar med att registrera, hålla reda på och analysera fysiska testresultat.",
      subtitle2: "Testelope gör det enkelt att registrera, spåra och analysera tester. Testdatan omvandlas till värdefull information som hjälper dig att bättre förstå för hur resultaten kan förbättras.",
      actionText: "Kom igång gratis utan att registrera kreditkort och starta din enkla testhantering nu!",
      getStarted: "Kom igång",
      login: "Logga in",
      // Verify
      verifyAccountTitle: "Aktivera ditt konto",
      verifyAccountDescription: "Ett mail har skickats till email adressen du angav när du skapade kontot. Följ instruktionerna i mailet för aktivera ditt konto. Så snart ditt konto är verifierat kan du logga in.",
      verifyAccountEmailHint: "Om du inte kan hitta aktiverings mailet, kolla din skräppost eller ",
      resendActivation: "skicka aktiverings mailet igen",
    // Dashboard
      welcome: "Välkommen",
      totalTests: "Totalt antal tester",
      totalTeams:"Totalt antal lag",
      totalPlayers: "Totalt antal spelare",
      totalResults: "Totalt antal registrerade resultat",
      testCompletion: "Totalt testslutförande",
      latestUpdatedTests: "Senast uppdaterade tester",
      latestUpdatedTeams: "Senast uppdaterade lag",
      viewAll: "Visa alla",
      updated: "Uppdaterad",
    // Menu
      home: "Hem",
      myTeams: "Mina lag",
      myAccount: "Mitt konto",
      help: "Hjälp",
      logout: "Logga ut",
      closeMenu: "Göm menyn",
      openMenu: "Fäst menyn",
    // Account
      userProfile: "Användarprofil",
      userProfileInfo: "Var vänlig uppdatera profilen med din information nedan",
      userSettings: "Användarinställningar",
      userSettingsInfo: "Anpassa din upplevelse genom att ändra inställningarna nedan",
      firstName: "Förnamn",
      lastName: "Efternamn",
      emailAddress: "Email adress",
      phoneNumber: "Telefonnummer",
      save: "Spara",
      language: "Språk",
    // Help
      howToVideos: "Instruktionsvideor",
      movieHowToCreateaNewTeam: "Hur skapar man ett nytt lag",
      movieHowToAddPlayers: "Hur lägger man till spelare till ett lag",
      movieHowToRegisterResults: "Hur registrerar man test resultat",
      movieHowToUseFilters: "Hur använder man filter",
      movieHowToPrint: "Hur man skriver ut inviduella resultat",
    // Teams
      search: "Sök",
      team: "lag",
      addNewTeam: "Lägg till nytt lag",
      addNewTeamDescription: "För att skapa ett nytt lag var vänlig fyll i alla fälten nedan och tryck på Lägg till knappen. Du kan editera laginställningar i lag översikten senare.",
      teamName: "Lagnamn",
      teamDescription: "Beskrivning",
      ageGroup: "Årsgrupp",
      startingYear: "Startår",
      year: "år",
      testProfile: "Testprofil",
      clear: "Rensa",
      close: "Stäng",
      add: "Lägg till",
      teamExistsError: "Ett lag med samma namn finns redan",
      players: "spelare",
      managers: "ledare",
      tests: "tester",
      hasBeenUpdated: "har blivit uppdaterad",
      haveBeenAdded: "har blivit tillagd",
      editTeam: "Editera lag",
      editTeamDescription: "För att editera laget, var vänlig uppdatera fälten nedan och tryck sedan på 'Spara' knappen",
      noTeamsFound: "Inga lag funna",
      noPlayersFound: "Inga spelare funna",
      playerName: "Spelarnamn",
      email: "E-mail",
      editPlayerDescription: "För att editera spelaren, var vänlig uppdatera fälten nedan och tryck sedan på 'Spara' knappen",
      addNewPlayer: "Lägg till spelare",
      addNewPlayerDescription: "För att lägga till en ny spelare vänligen fyll i fälten ned och tryck på 'Lägg till' knappen. Du kan editera spelaren under spelardetaljer senare.",
      print: "Skriv ut",
      overview: "Översikt",
      filterTests: "Filtrera tester",
      filterPlayers: "Filtrera spelare",
      noValidValue: "Ej giltigt värde. Exkluderad från statistiken.",
      notSaved: "Inte sparad.",
      max: "Max",
      min: "Min",
      median: "Median",
      average: "Medelvärde",
      pass: "Godkänt",
      fail: "Underkänt",
      hasBeenDeleted: "har bilvit borttagen",
      delete: "Ta bort",
      deletePlayerConfirmation: "Ta bort spelare?",
      deletePlayerConfirmationDesc: "Är du säker att du vill ta bort spelaren",
      edit: "Editera",
      player: "Spelare",
      manager: "Ledare",
      deleteTeamConfirmation: "Ta bort lag?",
      deleteTeamConfirmationDesc: "Är du säker att du vill ta bort laget",
      rememberToSave: "Kom ihåg att spara då och då...",
      name: "Namn",
      searchManagerToAdd: "Sök lagledare att lägga till",
      createWithoutEmail: "Skapa utan e-postadress",
      comparison: "Jämförelse"
    }
});