import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import {Toolbar} from 'components'
import { useApi } from 'hooks';
import TeamsTable from '../Teams/components/TeamsTable'
import InstructionsTable from '../Instructions/components/InstructionsTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        lexGrow: 1,
    },
    content: {
        marginTop: theme.spacing(2)
    },
    breadcrumbs:{
        margin: '1rem'
    }
}));

const PlayerDetails = props => {

    const { callApi } = useApi();
    const classes = useStyles();
    const history = useHistory();
    const [player, setPlayer] = useState({ player: {}, isFetching: false });
    const [teams, setTeams] = useState({ teams: [], isFetching: false });
    const [instructions, setInstructions] = useState({ instructions: [], isFetching: false });
    const [searchFilter, setSerarchFilter] = useState('');
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const userDisplayName = (player) => {
       if(player.firstName !== undefined)
       {
            var displayName = player.firstName.trim();
            if (player.lastName) {
                displayName += ' ' + player.lastName;
            }
            return displayName;
       }
       return '';
    }

    useEffect(() => {
        const fetchPlayer = async () => {
            try {
                setPlayer({ player: player.player,  isFetching: true });
                const response = await callApi('/users/' + props.match.params.playerId, 'get', null, null);
                setPlayer({ player: response, isFetching: false });
            } catch (e) {
                console.log(e);
                setPlayer({ player: player.player, isFetching: false });
            }
        };
        fetchPlayer();

        const fetchTeams = async () => {
            try {
                setTeams({ teams: teams.teams,  isFetching: true });
                const response = await callApi('/users/' + props.match.params.playerId + "/teamMemberShips", 'get', null, null);
                
                let playerTeams = [];

                response.map(async a => { 
                    const response = await callApi('/teams/' + a.teamId + '?_embed=teamMemberShips', 'get', null, null);
                    playerTeams.push(response);
                });
                
                setTeams({ teams: playerTeams, isFetching: false });
            } catch (e) {
                console.log(e);
                setTeams({ teams: teams.teams, isFetching: false });
            }
        };
        fetchTeams();

        const fetchInstructions = async () => {
            try {
                setInstructions({ instructions: instructions.instructions, isFetching: true });
                const response = await callApi('/users/' + props.match.params.playerId + "/instructions", 'get', null, null);
                setInstructions({ instructions: response, isFetching: false });
            } catch (e) {
                console.log(e);
                setInstructions({ instructions: instructions.instructions, isFetching: false });
            }
        };
        fetchInstructions();

    }, []);

    const filterData = event => {
        setSerarchFilter(event.target.value);
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Link color="inherit" href="#" onClick={() => {
                    history.push('/players');
                }}>Players
                </Link>
                <Typography color="textPrimary">{userDisplayName(player.player)}</Typography>
            </Breadcrumbs>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered >
                    <Tab label="Overview" />
                    <Tab label="Teams" />
                    <Tab label="Instructions" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    Overview
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Toolbar searchChange={filterData} tableName="teams"/>
                    <div className={classes.content}>
                        <TeamsTable teams={teams.teams} filter={searchFilter} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Toolbar searchChange={filterData} tableName="instructions"/>
                    <div className={classes.content}>
                        <InstructionsTable instructions={instructions.instructions} filter={searchFilter} />
                    </div>
                </TabPanel>
            </Paper>
        </div>
    );
};

export default PlayerDetails;
