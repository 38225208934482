import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography
} from '@material-ui/core';
// Testelope
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/login.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'right',
    flexBasis: '570px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  subtitle: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  subtitle2: {
    paddingBottom: '2rem',
  },
  actiontext:{
    fontWeight: '500'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  signInButtonContainer:{
    paddingTop: '2rem'
  }
}));

const Login = props => {

  const { history } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();

  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if(isAuthenticated){
      history.push('/overview');
    }
  }, [isAuthenticated, history]);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container>
        <Grid
          className={classes.quoteContainer}
          item
          lg={6}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1">
                {strings.qoute1}
              </Typography>
              <Typography
                className={classes.quoteText}
                variant="h1">
                {strings.qoute2}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={6}
          xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}>
                <Typography
                  className={classes.title}
                  variant="h1">
                  {strings.welcomeTitle}
                </Typography>
                <Typography
                  className={classes.subtitle}
                  color="textSecondary"
                  variant="subtitle1">
                  {strings.subtitle1}
                </Typography>
                <Typography
                  className={classes.subtitle2}
                  color="textSecondary"
                  variant="subtitle1">
                  {strings.subtitle2}
                </Typography>
                <Typography
                  className={classes.actiontext}
                  color="textSecondary"
                  variant="body2">
                  {strings.actionText}
                </Typography>
                <div className={classes.signInButtonContainer}>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => loginWithRedirect()}>
                  {strings.getStarted}
                </Button>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default withRouter(Login);