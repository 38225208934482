import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getReturnPath } from '../../helpers';

const Start = props => {

    const { history } = props;

    const {
        isAuthenticated,
        error
    } = useAuth0();

    useEffect(() => {

        if (isAuthenticated) {
            const previouslocation = getReturnPath()

            previouslocation ? history.push(previouslocation) : history.push('/overview');
        }

        if (!isAuthenticated && !window.location.search.includes('code=') && !window.location.search.includes('?error=') && !error) {
            history.push('/login');
        }

        if(error) {
            history.push('/verify');
        }

    }, [history, isAuthenticated, error]);

    return (
        <p>Loading...</p>
    );
};

export default Start;
