import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { LanguageContext } from 'context';
import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

import Autocomplete from 'react-autocomplete';
import { useApi } from 'hooks';

const useStyles = makeStyles(theme => ({
    root: {},
    highLighted: {
        background: 'lightgrey'
    },
    searchManagerToAdd: {
        width: '300px'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const TeamManagers = props => {
    const { managers, addManager, removeManager, ...rest } = props;
    const { strings } = useContext(LanguageContext);
    const classes = useStyles();
    const { callApi } = useApi();

    const [searchResults, setSearchResults] = useState([]);

    const [addManagerName, setAddManagerName] = useState('');

    const loadSearchResults = async (value) => {
        if (value.lenght < 2) {
            return;
        }

        const searchResults = await callApi("/users/search?q=" + value, 'get');
        setSearchResults(searchResults.users);
    }

    return (
        <div className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{strings.name}</TableCell>
                        <TableCell>{strings.email}</TableCell>
                        <TableCell>{strings.phoneNumber}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {managers.map((manager) => {
                        return (
                            <TableRow key={manager.user.id}>
                                <TableCell>
                                    <div className={classes.nameContainer}>
                                        <Avatar
                                            className={classes.avatar}
                                            src={manager.user.picture}>
                                            <PersonRoundedIcon />
                                        </Avatar>
                                        {manager.user.firstName} {manager.user.lastName}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {manager.user.email}
                                </TableCell>
                                <TableCell>
                                    {manager.user.phoneNumber}
                                </TableCell>
                                <TableCell style={{ width: 80 }} align="right">
                                    <Tooltip title={strings.delete + ' ' + strings.manager.toLowerCase()} arrow>
                                        <IconButton
                                            color="primary"
                                            aria-label="Print" component="span"
                                            onClick={() => removeManager(manager.user)} >
                                            <DeleteForeverIcon style={{ color: '#ed6663' }} fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Autocomplete
                                getItemValue={(item) => item.id.toString()}
                                items={searchResults}
                                shouldItemRender={(item, value) => true}
                                renderItem={(item, highlighted) => {
                                    return (
                                        <div key={item.id} className={highlighted ? classes.highLighted : ''}>
                                            <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={item.picture}>
                                                    <PersonRoundedIcon />
                                                </Avatar>
                                                {item.firstName} {item.lastName} ({item.email || "No e-mail set" })
                                            </div>
                                        </div>
                                    );
                                }}
                                renderInput={(props) => {
                                    return (
                                        <TextField
                                            label={strings.searchManagerToAdd}
                                            className={classes.searchManagerToAdd}
                                            {...props}
                                        />
                                    );
                                }}
                                value={addManagerName}
                                onChange={(e, value) => {
                                    setAddManagerName(value);
                                    loadSearchResults(value);
                                }}
                                onSelect={async (value, item) => {
                                    await addManager(item);
                                    setAddManagerName('');
                                }}
                                menuStyle={
                                    {
                                        zIndex: 999,
                                        borderRadius: '3px',
                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '2px 0',
                                        fontSize: '90%',
                                        position: 'fixed',
                                        overflow: 'auto'
                                    }
                                }
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

TeamManagers.propTypes = {
    managers: PropTypes.array.isRequired,
    addManager: PropTypes.func.isRequired,
    removeManager: PropTypes.func.isRequired
};

export default TeamManagers;
