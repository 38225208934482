import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material UI
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    searchForm:{
        flexGrow: 1,
        paddingLeft: '14rem',
    },
    formControl: {
        minWidth: 160,
        marginRight: '3.5rem'
      },
      saveButton:{
          marginRight: '2rem'
      }
}));

const OverviewToolbar = props => {

    const { 
        className, 
        searchChange, 
        searchLabel,
        handleSave,
        navigateToRegisterResults,
        ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <div className={classes.searchForm}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder={"Search " + searchLabel}
                    onChange={searchChange}/>
                </div>
                <span className={classes.spacer} />
                <div>
                <Button
                    className={classes.saveButton}
                    color="primary"
                    onClick={(e) => navigateToRegisterResults(e, 3)}
                    variant="contained">Register results
                </Button>
                </div>
            </div>
        </div>
    );
};

OverviewToolbar.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default OverviewToolbar;
