import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// MaterialUI
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles, makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Button
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
// Testelope
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    tableRow: {

    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    formControlAges: {
        display: 'flex',
        flexGrow: 1,
        placeContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    formTextfields: {
        placeContent: 'space-between',
    },
    formAvatar: {
        paddingBottom: '1rem',
    },
    input: {
        display: 'none'
    },
    editPictureIcon: {
        border: "1px solid #000"
    },
    emailerror: {
        color: 'Red'
    },
}));

const TeamPlayers = props => {
    const { className, users, editPlayer, deletePlayer, filter, showUserOverview, ...rest } = props;
    const { strings } = useContext(LanguageContext);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const [playerForm, setPlayerForm] = useState({
        playerId: null,
        playerFirstName: '',
        playerLastName: '',
        playerEmail: '',
        playerAvatar: '',
        playerAvatarPreview: ''
    });
    const [errors, setErrors] = useState({
        emailErrors: null
    });

    const handleSelectAll = event => {
        const { players } = props;

        let selectedPlayers;

        if (event.target.checked) {
            selectedPlayers = players.map(player => player.userId);
        } else {
            selectedPlayers = [];
        }

        setSelectedPlayers(selectedPlayers);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPlayers.indexOf(id);
        let newSelectedPlayers = [];

        if (selectedIndex === -1) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers, id);
        } else if (selectedIndex === 0) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers.slice(1));
        } else if (selectedIndex === selectedPlayers.length - 1) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedPlayers = newSelectedPlayers.concat(
                selectedPlayers.slice(0, selectedIndex),
                selectedPlayers.slice(selectedIndex + 1)
            );
        }

        setSelectedPlayers(newSelectedPlayers);
    };

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    }

    const handleDialogOpen = (player) => {

        setPlayerForm({
            playerId: player.user.id,
            playerFirstName: player.user.firstName,
            playerLastName: player.user.lastName,
            playerEmail: player.user.email,
            playerAvatarPreview: player.user.picture
        });

        setDialogOpen(true);
    };

    const handleDialogDeleteOpen = (player) => {

        setPlayerForm({
            playerId: player.user.id,
            playerFirstName: player.user.firstName,
            playerLastName: player.user.lastName,
            playerEmail: player.user.email,
            playerAvatarPreview: player.user.picture
        });

        setDialogDeleteOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogDeleteClose = () => {
        setDialogDeleteOpen(false);
    }

    const validateEmail = (email) => {
        if (!email) {
            return undefined;
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(email)) {
            return "Please enter valid email address.";
        }
        return undefined;
    };

    const handleDialogSubmit = (player) => {
        if (!player.playerFirstName || !player.playerLastName) {
            return;
        }
        const validationErrors = validateEmail(player.playerEmail);
        setErrors({ emailErrors: validationErrors });

        if (!validationErrors) {
            editPlayer(player);
            setDialogOpen(false);
        }
    };

    const handleDialogDelete = (player) => {
        deletePlayer(player)
        setDialogDeleteOpen(false);
    }

    const handlePlayerFormChange = (event) => {
        const name = event.target.name;
        setPlayerForm({
            ...playerForm,
            [name]: event.target.value,
        });
    };

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const filterPlayers = (playerList, searchWord) => {
        if (users.length === 0)
            return;

        if (playerList !== undefined) {
            return playerList.filter((player) => {
                return userDisplayName(player).toLowerCase().indexOf(searchWord.toLowerCase()) !== -1;
            });
        }
    };

    const userDisplayName = (player) => {
        if (player.user.firstName) {
            var displayName = player.user.firstName.trim();
            if (player.user.lastName) {
                displayName += ' ' + player.user.lastName;
            }
            return displayName;
        }
        return '';
    }

    const handlePictureChange = async event => {
        event.preventDefault();
        let file = event.target.files[0];

        if (file) {
            const base64string = await convertFileToBase64(file)

            setPlayerForm({
                ...playerForm,
                playerAvatar: base64string,
                playerAvatarPreview: base64string
            });
        }
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {

            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const SmallAvatar = withStyles((theme) => ({
        root: {
            width: 22,
            height: 22,
            border: `2px solid ${theme.palette.background.paper}`,
        },
    }))(Avatar);


    if (users.length === 0) {
        return (<div>
            <span>{strings.noPlayersFound}</span>
        </div>);
    }

    return (
        <div>
            <Card
                {...rest}
                className={clsx(classes.root, className)}>
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedPlayers.length === users.length}
                                            color="primary"
                                            indeterminate={
                                                selectedPlayers.length > 0 &&
                                                selectedPlayers.length < users.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell> */}
                                        <TableCell>{strings.playerName}</TableCell>
                                        <TableCell>{strings.email}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterPlayers(users, filter).slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((player, index) => (
                                        <TableRow
                                            className={classes.tableRow}
                                            hover
                                            key={index}
                                            selected={selectedPlayers.indexOf(player.user.id) !== -1} >
                                            {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedPlayers.indexOf(player.id) !== -1}
                                                color="primary"
                                                onChange={event => handleSelectOne(event, player.id)}
                                                value="true"
                                                onClick={event => handleCheckboxClick(event)} />
                                        </TableCell> */}
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Avatar
                                                        className={classes.avatar}
                                                        src={player.user.picture}>
                                                        <PersonRoundedIcon />
                                                    </Avatar>
                                                    <Typography variant="body1">{userDisplayName(player)}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {player.user.email}
                                            </TableCell>
                                            <TableCell style={{ width: 80 }} align="right">
                                                <Tooltip title={strings.print + ' ' + strings.player.toLowerCase()} arrow>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Print" component="span"
                                                        onClick={() => props.showUserOverview(player)} >
                                                        <PrintIcon fontSize='inherit' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ width: 80 }} align="right">
                                                <Tooltip title={strings.edit + ' ' + strings.player.toLowerCase()} arrow>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Print" component="span"
                                                        onClick={() => handleDialogOpen(player)} >
                                                        <EditIcon fontSize='inherit' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ width: 80 }} align="right">
                                                <Tooltip title={strings.delete + ' ' + strings.player.toLowerCase()} arrow>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Print" component="span"
                                                        onClick={() => handleDialogDeleteOpen(player)} >
                                                        <DeleteForeverIcon style={{ color: '#ed6663' }} fontSize='inherit' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                    <TablePagination
                        component="div"
                        count={filterPlayers(users, filter).length}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </CardActions>
            </Card>
            {/* Edit Dialog */}
            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{strings.edit} {playerForm.playerFirstName} {playerForm.playerLastName} </DialogTitle>
                <DialogContent>
                    <div className={classes.formAvatar}>
                        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(event) => handlePictureChange(event)} />
                        <label htmlFor="icon-button-file">
                            <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={<SmallAvatar><AddCircleIcon style={{ color: '#000000' }} /></SmallAvatar>}>
                                <Avatar
                                    src={playerForm.playerAvatarPreview}>
                                    <PersonRoundedIcon />
                                </Avatar>
                            </Badge>
                        </label>
                    </div>
                    <DialogContentText>
                        {strings.editPlayerDescription}
                    </DialogContentText>
                    <div className={classes.formControlAges}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="playerFirstName"
                            label={strings.firstName}
                            type="email"
                            style={{ minWidth: '45%' }}
                            value={playerForm.playerFirstName}
                            inputProps={{
                                name: 'playerFirstName',
                                id: 'age-native-simple',
                            }}
                            onChange={handlePlayerFormChange}
                            required />
                        <TextField
                            margin="dense"
                            id="playerLastName"
                            label={strings.lastName}
                            type="email"
                            style={{ minWidth: '45%' }}
                            value={playerForm.playerLastName}
                            inputProps={{
                                name: 'playerLastName',
                                id: 'age-native-simple',
                            }}
                            onChange={handlePlayerFormChange}
                            required />
                    </div>
                    <TextField
                        margin="dense"
                        id="playerEmail"
                        label={strings.emailAddress}
                        type="email"
                        fullWidth
                        value={playerForm.playerEmail ?? ''}
                        inputProps={{
                            name: 'playerEmail',
                            id: 'age-native-simple',
                        }}
                        onChange={handlePlayerFormChange} />

                    <div className={classes.emailerror}>
                        {errors.emailErrors}
                    </div>
                </DialogContent>


                <DialogActions>
                    <Button onClick={handleDialogClose} color="default">
                        {strings.close}
                    </Button>
                    <Button onClick={() => handleDialogSubmit(playerForm)} color="primary" autoFocus>
                        {strings.save}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Delete Dialog */}
            <Dialog
                fullScreen={fullScreen}
                open={dialogDeleteOpen}
                onClose={handleDialogDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {strings.deletePlayerConfirmation}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {strings.deletePlayerConfirmationDesc} {playerForm.playerFirstName} {playerForm.playerLastName}?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose} color="default">
                        {strings.close}
                    </Button>
                    <Button onClick={() => handleDialogDelete(playerForm)} color="primary" autoFocus>
                        {strings.delete}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

TeamPlayers.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired,
    filter: PropTypes.string
};

export default TeamPlayers;
