import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));


const ResultsDistributionGraph = props => {
    const { className, dataSet, ...rest } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                                variant="h4"
                            >
                                Results distribution
                            </Typography>
                        </Grid>
                    </Grid>
                    <Line data={dataSet}>
                    </Line>
                </CardContent>
            </Card>
        </div>
    );
}

ResultsDistributionGraph.propTypes = {
    className: PropTypes.string,
    dataSet: PropTypes.any
};

export default ResultsDistributionGraph;