import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// Testelope
import { ProfileContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 70,
    height: 70
  },
  name: {
    color: '#37474f',
    marginTop: theme.spacing(2)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const { profile, profileLoading } = useContext(ProfileContext);
  const classes = useStyles();

  const {
    isAuthenticated
  } = useAuth0();

  if (profileLoading) {
    return (
        <div 
          {...rest}
          className={clsx(classes.root, className)}>
            <CircularProgress color="inherit" />
        </div>
    )
  }

  if(isAuthenticated)
  {
    return (
      <div
        {...rest}
        className={clsx(classes.root, className)}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={profile.avatar}
          to="/profile"
        />
        <Typography
          className={classes.name}
          variant="h4">
          {profile.displayName} 
        </Typography>
      </div>
    );
  } else return null;
};


Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
