import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// MaterialUI
import { withStyles, makeStyles } from '@material-ui/styles';
import { Button, Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
// Testelope
import { SearchInput } from 'components';
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControlAges: {
        display: 'flex',
        flexGrow: 1,
        placeContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    formTextfields: {
        placeContent: 'space-between',
    },
    emailerror: {
        color: 'Red'
    },
    formAvatar: {
        paddingBottom: '1rem',
    },
    input: {
        display: 'none'
    },
    editPictureIcon: {
        border: "1px solid #000"
    }
}));

const PlayersToolbar = props => {

    const { className, searchChange, tableName, addNewPlayer, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { strings } = useContext(LanguageContext);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [newPlayerForm, setNewPlayerForm] = useState({
        playerFirstName: '',
        playerLastName: '',
        playerEmail: '',
        playerAvatar: ''
    })
    const [errors, setErrors] = useState({
        emailErrors: null
    });
    const [emailNotAvailable, setEmailNotAvailable] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogSubmit = (player) => {

        if (!player.playerFirstName || !player.playerLastName){
            return;
        }

        var validationErrors = validateEmail(player.playerEmail)
        setErrors({ emailErrors: validationErrors });

        if (validationErrors === undefined) {
            addNewPlayer(player);
            setDialogOpen(false);

            handleClear();
        }
    };

    const validateEmail = (email) => {
        if (emailNotAvailable === true) {
            return undefined;
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(email)) {
            return "Please enter valid email address.";
        }
        return undefined;
    };

    const handleNewPlayerFormChange = (event) => {
        const name = event.target.name;
        setNewPlayerForm({
            ...newPlayerForm,
            [name]: event.target.value,
        });
    };

    const handleClear = () => {
        setNewPlayerForm({
            playerFirstName: '',
            playerLastName: '',
            playerEmail: '',
            playerAvatar: ''
        })
        setEmailNotAvailable(false);
        setErrors({ emailErrors: null });
    };

    const handlePictureChange = async event => {
        event.preventDefault();
        let file = event.target.files[0];

        const base64string = await convertFileToBase64(file)

        setNewPlayerForm({
            ...newPlayerForm,
            playerAvatar: base64string
        });
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const SmallAvatar = withStyles((theme) => ({
        root: {
            width: 22,
            height: 22,
            border: `2px solid ${theme.palette.background.paper}`,
        },
    }))(Avatar);

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <div className={classes.spacer}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder={`${strings.search} ${tableName}`}
                        onChange={searchChange} />
                </div>
                <span className={classes.spacer} />
                <div>
                    <Button variant="outlined" color="primary" onClick={handleDialogOpen}>
                        {strings.addNewPlayer}
                    </Button>
                    <Dialog
                        fullScreen={fullScreen}
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{strings.addNewPlayer}</DialogTitle>
                        <DialogContent>
                            <div className={classes.formAvatar}>
                                <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={(event) => handlePictureChange(event)} />
                                <label htmlFor="icon-button-file">
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        badgeContent={<SmallAvatar><AddCircleIcon style={{ color: '#000000' }} /></SmallAvatar>}>
                                        <Avatar
                                            src={newPlayerForm.playerAvatar}>
                                            <PersonRoundedIcon />
                                        </Avatar>
                                    </Badge>
                                </label>
                            </div>
                            <DialogContentText>
                                {strings.addNewPlayerDescription}
                            </DialogContentText>
                            <div className={classes.formControlAges}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="playerFirstName"
                                    label={strings.firstName}
                                    type="email"
                                    style={{ minWidth: '45%' }}
                                    value={newPlayerForm.playerFirstName}
                                    inputProps={{
                                        name: 'playerFirstName',
                                        id: 'age-native-simple',
                                    }}
                                    onChange={handleNewPlayerFormChange}
                                    required />
                                <TextField
                                    margin="dense"
                                    id="playerLastName"
                                    label={strings.lastName}
                                    type="email"
                                    style={{ minWidth: '45%' }}
                                    value={newPlayerForm.playerLastName}
                                    inputProps={{
                                        name: 'playerLastName',
                                        id: 'age-native-simple',
                                    }}
                                    onChange={handleNewPlayerFormChange}
                                    required />
                            </div>
                            <TextField
                                margin="dense"
                                id="playerEmail"
                                label={strings.email}
                                type="email"
                                fullWidth
                                value={newPlayerForm.playerEmail}
                                inputProps={{
                                    name: 'playerEmail',
                                    id: 'age-native-simple',
                                }}
                                onChange={handleNewPlayerFormChange}
                                disabled={emailNotAvailable}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={emailNotAvailable} onChange={() => {
                                    setNewPlayerForm({...newPlayerForm, 'playerEmail': ''});
                                    setEmailNotAvailable(!emailNotAvailable);
                                }} ></Checkbox>}
                                label={strings.createWithoutEmail}
                            />
                            <div className={classes.emailerror}>
                                {errors.emailErrors}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClear} color="default">
                                {strings.clear}
                            </Button>
                            <Button onClick={handleDialogClose} color="default">
                                {strings.close}
                            </Button>
                            <Button onClick={() => handleDialogSubmit(newPlayerForm)} color="primary">
                                {strings.add}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

PlayersToolbar.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default PlayersToolbar;
