import React, { useState, createContext, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
// Testelope
import { useApi } from 'hooks';

export const ProfileContext = createContext({
    profile: {}
});

export function ProfileProvider({ children }) {
    
    const {
        user,
      } = useAuth0();

    const { callApi } = useApi();

    const [profileLoading, setProfileLoading] = useState(true);

    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        avatar: '',
        displayName: ''
      });
    
    const getDisplayName = (profile, user) => {

        let username = profile.firstName;
    
        if(username !== null && username !== undefined && username !== "" 
            && profile.lastName !== null && profile.lastName !== undefined && profile.lastName !== "") {
          username = username + ' ' + profile.lastName 
        }
    
        if(username !== null && username !== undefined && username !== "")
        {
            return username;
        }
        else {
           return user !== undefined ? user.email.substr(0, user.email.indexOf('@')) : ''
        }
    }

    useEffect(() => {  
        const fetchCurrentUser = async () => {
            try {
                const response = await callApi('/users/current', 'get', null, null);

                const profileDto = {
                    firstName: response.user.firstName || '',
                    lastName: response.user.lastName || '',
                    phoneNumber: response.user.phoneNumber || '',
                    email: user !== undefined ? user.email : '',
                    avatar: response.user.picture !== undefined ? response.user.picture : user !== undefined ? user.picture : '',
                    displayName: getDisplayName(response.user, user)
                };

                if(JSON.stringify(profile) !== JSON.stringify(profileDto)) {
                    setProfile(profileDto);
                }
            } catch (e) {
                console.log(e);
            }
        };

        Promise.all([fetchCurrentUser(), ]).then(() => setProfileLoading(false));

      }, [user, profile]);

    const provider = {
        profile,
        profileChange: async (profile) => {
            const updateCurrentUser = async () => {
                console.log(profile);
                let userDto = {
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    phoneNumber: profile.phoneNumber,
                    email: user.email, 
                    picture: profile.avatar ?? user.picture
                }
            
                await callApi('/users/current', 'post', userDto, null);

                setProfile(userDto);
            }
            updateCurrentUser();
        },
        profileLoading
    };

    return (
    <ProfileContext.Provider value={provider}>
        {children}
    </ProfileContext.Provider>
    );
};