import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const MinutesSecondsInput = props => {

    const ValidationTextField = withStyles({
        root: {
            '& input:valid + fieldset': {
                borderColor: 'green',
                borderWidth: 2,
            },
            '& input:invalid + fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
            '& input:valid:focus + fieldset': {
                borderLeftWidth: 6,
                padding: '4px !important',
            },
        },
    })(TextField);

    const UpdateValue = (value) => {
        value = value.replace(',', ':').replace('.', ':');
        if (value && !/^(\d){0,2}:?0?(\d){0,2}$/.test(value))
            return;
        const normalizedValue = NormalizeValue(value);
        props.updateValue(props.userId, props.occasionId, props.instructionId, value, normalizedValue);
    };

    const NormalizeValue = (value) => {
        if (!value) {
            return null;
        }
        if (value.indexOf(':') > 0) {
            var minutes = value.substring(0, value.indexOf(':'));
            var seconds = value.substring(value.indexOf(':') + 1);
            if (!seconds)
                seconds = 0;

            return parseInt(minutes) * 60 + parseInt(seconds);
        }
        else {
            return null;
        }
    };

    const FormatValue = (value) => {
        if (value === null) { return ''; }
        var minutes = Math.floor(value / 60);
        var seconds = value % 60;
        return minutes + ":" + seconds.toFixed(0).padStart(2, '0');
    }

    if (props.excel) {
        if (props.statistic) {
            return (
                <input value={FormatValue(props.value) ?? ""} disabled={true}></input>
            );
        }

        return (
            <input type="text"
                value={props.value ?? ""}
                onChange={(e) => UpdateValue(e.target.value)}
                onClick={(e) => e.target.select()}
                disabled={props.readOnly}
                tabIndex={props.tabIndex}
                onBlur={() => props.saveSingleResult(props.userId, props.occasionId, props.instructionId)}></input>
        );
    }
    return (
        <ValidationTextField
            required
            id="validation-outlined-input"
            variant="outlined"
            InputProps={{
                endAdornment: <InputAdornment position="end">Min:Sec</InputAdornment>
            }} s
            size="small"
            onChange={(e) => UpdateValue(e.target.value)}
            value={props.value} />
    );
}

export default MinutesSecondsInput;