import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';

import { ResultsToolbar, TransitionModal } from 'components'

import {
  ReadOnly,
  CountInput,
  KilosInput,
  MetersInput,
  TempInput,
  SecondsInput,
  FailPassInput,
  MinutesSecondsInput,
  CentimetersInput
} from 'components'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function Row(props) {
  const { row, tab, searchFilter, resultChange, openResultModal, closeResultModal, onlyShowPlayersWithNoResult, inModal } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const inputComponents = {
    Count: CountInput,
    Kilos: KilosInput,
    Meters: MetersInput,
    Temp: TempInput,
    Seconds: SecondsInput,
    Centimeters: CentimetersInput,
    FailPass: FailPassInput,
    MinutesSeconds: MinutesSecondsInput
  }

  const filterPlayers = (playerList, occasion, searchWord, onlyShowPlayersWithNoResult) => {
    if (playerList !== undefined) {
      return playerList.filter((player) => {

        let showPlayer = player.firstName.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1

        if (onlyShowPlayersWithNoResult) {
          showPlayer = player[tab.name] === undefined || player[tab.name] === null;
        }
        return showPlayer;
      });
    }
  };

  const InputComponent = inputComponents[row.unit] || ReadOnly;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell padding="checkbox">
          {!inModal === true ?
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> : ''}
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="h6">{row.name}</Typography>
          <Typography variant="caption" display="block" gutterBottom>{row.unit}</Typography>
        </TableCell>
        <TableCell align="right" style={{ paddingRight: "3rem" }}>
          <Typography variant="h6">{(row.results.filter(x => x[tab.name] !== null).length * 100 / row.results.length).toFixed(1)}%</Typography>
          <Typography variant="caption" display="block" gutterBottom>{row.results.filter(x => x[tab.name] !== null).length}/{row.results.length} completed</Typography>
        </TableCell>
        <TableCell padding="checkbox">
          {!inModal === true ?
            <IconButton size="small" onClick={() => openResultModal(row, tab)}>
              <LaunchIcon />
            </IconButton> :
            <IconButton size="small" onClick={() => closeResultModal()}>
              <CloseIcon />
            </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open || inModal} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Player</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterPlayers(row.results, tab.name, searchFilter, onlyShowPlayersWithNoResult).map(playersRow => (
                    <TableRow key={playersRow.id}>
                      <TableCell padding="checkbox">
                        <Icon size="small">
                          <AccountCircleIcon />
                        </Icon>
                      </TableCell>
                      <TableCell>{playersRow.firstName}</TableCell>
                      <TableCell align="right">
                        <form className={classes.root} noValidate>
                          <InputComponent
                            value={row.results.find(result => result.id === playersRow.id)[tab.name]}
                            updateValue={resultChange}
                            userId={playersRow.id}
                            occasionName={tab.name}
                            instructionId={row.id} />
                        </form>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.any.isRequired,
  tab: PropTypes.any.isRequired,
  searchFilter: PropTypes.any,
  resultChange: PropTypes.any,
  onlyShowPlayersWithNoResult: PropTypes.any
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  right: {
    flexGrow: 1
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 500,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: '160px',
    marginRight: '2rem',
    maxHeight: 640,
    marginTop: '-5rem'
  },
  panel: {
    flexGrow: 2
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const TeamResults = props => {
  // Props
  const { team, saveResults, setResults } = props;
  // Styles
  const classes = useStyles();
  // state
  const [value, setValue] = React.useState(0);
  const [dropdownValue, setDropdownValue] = React.useState("Occasions");
  const [tabArray, setTabArray] = React.useState(team.occasions);
  const [panelArray, setPanelArray] = React.useState(team.instructions);
  const [searchFilter, setSerarchFilter] = React.useState('');
  const [cbxPlayersResultChecked, setCbxPlayersResultChecked] = React.useState(false);
  const [cbxUncompletedTestsChecked, setCbxUncompletedTestsChecked] = React.useState(false);
  const [openResultModal, setOpenResultModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [selectedTab, setSelectedTab] = React.useState();

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);

    if (event.target.value === 'Tests') {
      setTabArray(team.instructions);
      setPanelArray(team.occasions);
    }
    else {
      setTabArray(team.occasions);
      setPanelArray(team.instructions);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterPlayers = event => {
    setSerarchFilter(event.target.value);
  };

  const handlePlayersResultCheckbox = () => {
    if (cbxPlayersResultChecked) {
      setCbxPlayersResultChecked(false);
    }
    else {
      setCbxPlayersResultChecked(true);
    }
  }

  const handleUncompletedTestsCheckbox = () => {
    if (cbxUncompletedTestsChecked) {
      setCbxUncompletedTestsChecked(false);
    }
    else {
      setCbxUncompletedTestsChecked(true);
    }
  }

  const handleResultModalOpen = (row, tab) => {
    setSelectedRow(row);
    setSelectedTab(tab);
    setOpenResultModal(true);
  };

  const handleResultModalClose = () => {
    setOpenResultModal(false);
  };

  const resultModal = () => {
    return (
      <div>
        <Row
          key={selectedRow.name}
          row={selectedRow}
          tab={selectedTab}
          searchFilter={searchFilter}
          resultChange={setResults}
          openResultModal={null}
          closeResultModal={handleResultModalClose}
          onlyShowPlayersWithNoResult={cbxPlayersResultChecked}
          inModal={true} />
      </div>
    )
  }

  return (
    <div>
      <TransitionModal open={openResultModal} onClose={handleResultModalClose} content={resultModal}></TransitionModal>
      <ResultsToolbar
        className=""
        dropdownValue={dropdownValue}
        dropdownChange={handleDropdownChange}
        searchChange={filterPlayers}
        searchLabel="player"
        handleSave={saveResults}
        cbxPlayersResultChecked={cbxPlayersResultChecked}
        cbxPlayersResultChange={handlePlayersResultCheckbox}
        cbxUncompletedTestsChecked={cbxUncompletedTestsChecked}
        cbxUncompletedTestsChange={handleUncompletedTestsCheckbox} />

      <div className={classes.root}>

        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleTabChange}
          className={classes.tabs}>
          {tabArray.map((tab) => (
            <Tab label={tab.name} />
          ))}
        </Tabs>
        {tabArray.map((tab, index) => (
          <TabPanel value={value} index={index} className={classes.panel}>
            <Card>
              <CardContent>
                <div className={classes.details}>
                  <Typography gutterBottom variant="h3">{tab.name.toUpperCase()}</Typography>
                  <Typography className={classes.locationText} color="textSecondary" variant="body1"> {tab.description} </Typography>
                </div>
              </CardContent>
            </Card>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Name</TableCell>
                    <TableCell align="right" style={{ paddingRight: "3rem" }}>Progress</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {panelArray.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      tab={tab}
                      searchFilter={searchFilter}
                      resultChange={setResults}
                      openResultModal={handleResultModalOpen}
                      closeResultModal={null}
                      onlyShowPlayersWithNoResult={cbxPlayersResultChecked}
                      inModal={false} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        ))}
      </div>
    </div>
  );
}

export default TeamResults;

TeamResults.propTypes = {
  team: PropTypes.any.isRequired
};