import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    tableRow: {
        cursor : 'pointer'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const PlayersTable = props => {
    const { className, players, filter, ...rest } = props;

    const classes = useStyles();
    const history = useHistory();

    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleSelectAll = event => {
        const { players } = props;

        let selectedPlayers;

        if (event.target.checked) {
            selectedPlayers = players.map(player => player.userId);
        } else {
            selectedPlayers = [];
        }

        setSelectedPlayers(selectedPlayers);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPlayers.indexOf(id);
        let newSelectedPlayers = [];

        if (selectedIndex === -1) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers, id);
        } else if (selectedIndex === 0) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers.slice(1));
        } else if (selectedIndex === selectedPlayers.length - 1) {
            newSelectedPlayers = newSelectedPlayers.concat(selectedPlayers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedPlayers = newSelectedPlayers.concat(
                selectedPlayers.slice(0, selectedIndex),
                selectedPlayers.slice(selectedIndex + 1)
            );
        }

        setSelectedPlayers(newSelectedPlayers);
    };

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    }
    
    const viewPlayer = (event, id) => {
        const path = '/players/' + id
        history.push(path);
    }

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const filterPlayers = (playerList, searchWord) => {
        if (playerList !== undefined) {
            return playerList.filter((player) => {
                return userDisplayName(player).toLowerCase().indexOf(searchWord.toLowerCase()) !== -1;
            });
        }
    };

    const userDisplayName = (player) => {
        if(player.firstName !== undefined)
        {
             var displayName = player.firstName.trim();
             if (player.lastName) {
                 displayName += ' ' + player.lastName;
             }
             return displayName;
        }
        return '';
     }

    if (players.length === 0){
        return (<div>
            <span>No players found</span>
        </div>);
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedPlayers.length === players.length}
                                            color="primary"
                                            indeterminate={
                                                selectedPlayers.length > 0 &&
                                                selectedPlayers.length < players.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>E-mail</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterPlayers(players, filter).slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(player => (
                                    <TableRow
                                        className={classes.tableRow}
                                        hover
                                        key={player.userId}
                                        selected={selectedPlayers.indexOf(player.id) !== -1}
                                        onClick={event => viewPlayer(event, player.id)}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedPlayers.indexOf(player.id) !== -1}
                                                color="primary"
                                                onChange={event => handleSelectOne(event, player.id)}
                                                value="true"
                                                onClick={event => handleCheckboxClick(event)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={player.avatarUrl}>
                                                    {getInitials(player.firstName)}
                                                </Avatar>
                                                <Typography variant="body1">{userDisplayName(player)}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {player.email}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
                <TablePagination
                    component="div"
                    count={filterPlayers(players, filter).length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </CardActions>
        </Card>
    );
};

PlayersTable.propTypes = {
    className: PropTypes.string,
    players: PropTypes.array.isRequired,
    filter: PropTypes.string
};

export default PlayersTable;
