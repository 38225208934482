import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    }
}));

const InstructionNameCard = props => {
    const { className, name, description, min, max, avg, resultCount, ...rest } = props;

    const classes = useStyles();

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            variant="h4"
                        >
                            {name}
                        </Typography>
                        <Typography
                            className={classes.caption}
                            variant="caption">
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.difference}>
                    {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
                    <Typography variant="h3">Max: {max}</Typography>
                    <Typography variant="h3">Min: {min}</Typography>
                    <Typography variant="h3">Agv: {avg}</Typography>

                </div>

                <Typography
                    className={classes.caption}
                    variant="caption">
                    Based on {resultCount} results
                    </Typography>
            </CardContent>
        </Card>
    );
};

InstructionNameCard.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    avg: PropTypes.string,
    resultCount: PropTypes.number
};

export default InstructionNameCard;
