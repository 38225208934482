import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputComponentsMap from './InputComponentsMap'
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.body1,
        width: '800px',
        display: 'inline'
    },
    instruction: {
        border: '1px solid',
        padding: '6px',
        width: '800px',
        pageBreakInside: 'avoid',
        marginBottom: '5px'
    },
    heading: {
        fontWeight: 'bold'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderTop: '1px solid grey',
        tableLayout: 'auto',
        width: '100%'
    },
    firstColumn: {
        maxWidth: '100px',
        wordBreak: 'break-word'
    },
    cell: {
        margin: 0,
        border: '1px solid grey',
        whiteSpace: 'nowrap',
        borderTopWidth: '0px',
        height: '22px',
        padding: '3px',
        textAlign: 'right',
        color: '#000',
        '& input': {
            width: '25px',
            height: '22px',
            textAlign: 'right',
            color: '#000',
            borderWidth: '0px',
            background: 'inherit'
        }
    }
}));

const UserResultsOverview = props => {
    const { user, results, instructions, occasions, teamStatistics } = props;
    const classes = useStyles();
    const { strings } = useContext(LanguageContext);

    const GetStatsValue = (occasionId, instructionId, propertyName) => {
        let occasionInsructionStats = teamStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);
        if (occasionInsructionStats) {
            return occasionInsructionStats[propertyName];
        }
        return "";
    }

    const GetInstructionStats = (occasionId, instructionId) => {
        let occasionInsructionStats = teamStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);
        return occasionInsructionStats ?? null;
    }

    const InstructionOccasions = (instructionId) => {
        return occasions.filter(o => o.instructionIds.includes(instructionId));
    }

    if (!user.id) { return <></> }

    return (
        <>
            <div className={classes.root} id='printDiv'>
                {instructions.map((instruction, instructionIndex) => {
                    const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];

                    return (
                        <Fragment key={instructionIndex}>
                            <div className={classes.instruction} >
                                <div>{instruction.name}</div>
                                <table className={classes.table}>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        {occasion.name}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <td></td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                const result = results.find(r => r.occasionId === occasion.id && r.instructionId === instruction.id);
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        <span className={classes.heading}>{result && result.value}</span>
                                                    </td>
                                                )
                                            }
                                            )}
                                        </tr>
                                        <tr>
                                            <td className={classes.firstColumn}>{`${strings.min}/ #${strings.fail}`}</td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        <InputComponent
                                                            value={GetStatsValue(occasion.id, instruction.id, 'minimum')}
                                                            excel={true}
                                                            readOnly={true}
                                                            statistic={true}
                                                            statistics={GetInstructionStats(occasion.id, instruction.id)}
                                                            metric='minimum'
                                                            instruction={instruction} />
                                                    </td>
                                                )
                                            }
                                            )}
                                        </tr>
                                        <tr>
                                            <td className={classes.firstColumn}>{`${strings.max}/ #${strings.pass}`}</td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        <InputComponent
                                                            value={GetStatsValue(occasion.id, instruction.id, 'maximum')}
                                                            excel={true}
                                                            readOnly={true}
                                                            statistic={true}
                                                            statistics={GetInstructionStats(occasion.id, instruction.id)}
                                                            metric='maximum'
                                                            instruction={instruction} />
                                                    </td>
                                                )
                                            }
                                            )}
                                        </tr>
                                        <tr>
                                            <td className={classes.firstColumn}>{`${strings.average}/ ${strings.pass} %`}</td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        <InputComponent
                                                            value={GetStatsValue(occasion.id, instruction.id, 'average')}
                                                            excel={true}
                                                            readOnly={true}
                                                            statistic={true}
                                                            statistics={GetInstructionStats(occasion.id, instruction.id)}
                                                            metric='average'
                                                            instruction={instruction} />
                                                    </td>
                                                )
                                            }
                                            )}
                                        </tr>
                                        <tr>
                                            <td className={classes.firstColumn}>{strings.median}</td>
                                            {InstructionOccasions(instruction.id).map((occasion, occasionIndex) => {
                                                return (
                                                    <td key={occasionIndex} className={classes.cell}>
                                                        <InputComponent
                                                            value={GetStatsValue(occasion.id, instruction.id, 'median')}
                                                            excel={true}
                                                            readOnly={true}
                                                            statistic={true}
                                                            statistics={GetInstructionStats(occasion.id, instruction.id)}
                                                            metric='median'
                                                            instruction={instruction} />
                                                    </td>
                                                )
                                            }
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default UserResultsOverview;