import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import validate from 'validate.js';
import Axios from 'axios';
import 'react-perfect-scrollbar/dist/css/styles.css';
// MaterialUI
import { ThemeProvider } from '@material-ui/styles';
import { Auth0Provider } from "@auth0/auth0-react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
// Testelope
import { chartjs } from './helpers';
import theme from './theme';
import validators from './helpers/validators';
import Routes from './Routes';
import settings from './config';
import history from "./helpers/history";
import './assets/scss/index.scss';
import { LanguageProvider, ProfileProvider } from './context';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {

    const onRedirectCallback = (appState) => {
      history.push(
       appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
      );
    };

    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: 99,
            color: '#fff',
        },
    }));

    const classes = useStyles();
    const [proxySettings, setProxySettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => { 

      const fetchSettings = async () => {
          try {
              setIsLoading(true);

              const client = Axios.create({
                baseURL: '/api',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                timeout: 30000,
              });

              const response = await client.get('/settings')
              .then(response => {
                return Promise.resolve(response.data);
              }).catch(error => {
                return Promise.reject(error);
              });

              setProxySettings(response);

          } catch (e) {
              console.log(e);
              setIsLoading(false)
          }
      };
      
      Promise.all([fetchSettings()]).then(() => setIsLoading(false));
  }, []);

    if (isLoading) {
      return (
          <div>
              <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="inherit" />
              </Backdrop>
          </div>
      )
    }

    return (
      <Auth0Provider
      domain={settings.AUTH0_DOMAIN}
      clientId={settings.AUTH0_CLIENT_ID}
      audience={proxySettings.audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
        <LanguageProvider>
          <ProfileProvider>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Routes />
            </Router>
          </ThemeProvider>
          </ProfileProvider>
        </LanguageProvider>
       </Auth0Provider>
    );
  }

  export default App;
