import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { useApi } from 'hooks';
import InstructionsTable from './components/InstructionsTable'
import {Toolbar} from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  breadcrumbs:{
    margin: '1rem'
}
}));

const Instructions = () => {

  const { callApi } = useApi();
  const classes = useStyles();

  const [data, setData] = useState({ instructions: [], isFetching: false });
  const [searchFilter, setSerarchFilter] = useState('');

  useEffect(() => {
    const fetchInstructions = async () => {
      try {
        setData({ instructions: data.instructions, isFetching: true });

        const response = await callApi('/instructions', 'get', null, null);
        
        setData({ instructions: response, isFetching: false });
      } catch (e) {
        console.log(e);
        setData({ instructions: data.instructions, isFetching: false });
      }
    };
    fetchInstructions();
  }, []);

  const filterData = event => {
    setSerarchFilter(event.target.value);
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Typography color="textPrimary">Instructions</Typography>
      </Breadcrumbs>
      <Paper className={classes.root}>
      <Toolbar searchChange={filterData} tableName="instructions"/>
        <div className={classes.content}>
          <InstructionsTable instructions={data.instructions} filter={searchFilter} />
        </div>
      </Paper>
    </div>
  );
};

export default Instructions;
