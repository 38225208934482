import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    tableRow: {
        cursor : 'pointer'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));

const InstructionsTable = props => {
    const { className, instructions, filter, ...rest } = props;

    const classes = useStyles();
    const history = useHistory();
    
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleSelectAll = event => {
        const { instructions } = props;

        let selectedInstructions;

        if (event.target.checked) {
            selectedInstructions = instructions.map(instruction => instruction.id);
        } else {
            selectedInstructions = [];
        }

        setSelectedInstructions(selectedInstructions);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedInstructions.indexOf(id);
        let newSelectedInstructions = [];

        if (selectedIndex === -1) {
            newSelectedInstructions = newSelectedInstructions.concat(selectedInstructions, id);
        } else if (selectedIndex === 0) {
            newSelectedInstructions = newSelectedInstructions.concat(selectedInstructions.slice(1));
        } else if (selectedIndex === selectedInstructions.length - 1) {
            newSelectedInstructions = newSelectedInstructions.concat(selectedInstructions.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedInstructions = newSelectedInstructions.concat(
                selectedInstructions.slice(0, selectedIndex),
                selectedInstructions.slice(selectedIndex + 1)
            );
        }

        setSelectedInstructions(newSelectedInstructions);
    };

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    }

    const viewInstruction = (event, id) => {
        const path = '/instructions/' + id
        history.push(path);
    }

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const filterInstructions = (instructionList, searchWord) => {
        if (instructionList !== undefined) {
            return instructionList.filter((t) => {
                return t.name.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
            });
        }
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedInstructions.length === instructions.length}
                                            color="primary"
                                            indeterminate={
                                                selectedInstructions.length > 0 &&
                                                selectedInstructions.length < instructions.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Max</TableCell>
                                    <TableCell>Min</TableCell>
                                    <TableCell>Avg</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterInstructions(instructions, filter).slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(instruction => (
                                    <TableRow
                                        className={classes.tableRow}
                                        hover
                                        key={instruction.id}
                                        selected={selectedInstructions.indexOf(instruction.id) !== -1}
                                        onClick={event => viewInstruction(event, instruction.id)}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedInstructions.indexOf(instruction.id) !== -1}
                                                color="primary"
                                                onChange={event => handleSelectOne(event, instruction.id)}
                                                value="true"
                                                onClick={event => handleCheckboxClick(event)}/>
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.nameContainer}>
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={instruction.avatarUrl}>
                                                    {getInitials(instruction.name)}
                                                </Avatar>
                                                <Typography variant="body1">{instruction.name}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            123
                                        </TableCell>
                                        <TableCell>
                                            123
                                        </TableCell>
                                        <TableCell>
                                            123
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions className={classes.actions}>
                <TablePagination
                    component="div"
                    count={filterInstructions(instructions, filter).length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </CardActions>
        </Card>
    );
};

InstructionsTable.propTypes = {
    className: PropTypes.string,
    instructions: PropTypes.array.isRequired,
    filter: PropTypes.string
};

export default InstructionsTable;
