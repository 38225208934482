import React, { useState, useContext } from 'react';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Testelope
import { AccountProfile, AccountDetails, AccountSettings} from 'components';
import { ProfileContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const Profile = () => {

  const classes = useStyles();
  const { profile, profileChange, profileLoading } = useContext(ProfileContext);

  const [statusSnackbar, setStatusSnackbar] = useState({
    open: false,
    message: ''
  }); 

  const updateCurrentProfile = async (profile) => {
    console.log(profile);

    await profileChange(profile);
    setTimeout(() => {handleOpenStatusSnackbar(`Your profile has been updated`); }, 1000);
  };

  const handleOpenStatusSnackbar = (message) => {
    setStatusSnackbar({ open: true, message: message.toString() });
  };

  const handleCloseStatusSnackbar = () => {
      setStatusSnackbar({ open: false, message: '' });
  };

  if (profileLoading) {
    return (
        <div>
            <Backdrop className={classes.backdrop} open={profileLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4} >
        <Grid item lg={4} md={6} xs={12} >
          <AccountProfile profile={profile} />
        </Grid>
        <Grid container item lg={8} md={6} xl={8} xs={12} spacing={2} >
          <Grid item lg={12} md={12} xs={12}> 
            <AccountDetails profile={profile} updateCurrentProfile={updateCurrentProfile} />
          </Grid>
          <Grid item lg={12} md={12} xs={12}> 
            <AccountSettings profile={profile} updateCurrentProfile={updateCurrentProfile} />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={statusSnackbar.open}
        onClose={handleCloseStatusSnackbar}
        autoHideDuration={5000}
        message={statusSnackbar.message}
        action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseStatusSnackbar}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        }
      />
    </div>
  );

}; 

export default Profile;
