import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { useApi } from 'hooks';
import PlayersTable from './components/PlayersTable'
import {Toolbar} from 'components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    breadcrumbs:{
        margin: '1rem'
    }
}));

const Players = () => {

    const { callApi } = useApi();
    const classes = useStyles();
    const [data, setData] = useState({ players: [], isFetching: false });
    const [searchFilter, setSerarchFilter] = useState('');

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                setData({ players: data.players, isFetching: true });
                const response = await callApi('/users', 'get', null, null);
                setData({ players: response, isFetching: false });
            } catch (e) {
                console.log(e);
                setData({ players: data.players, isFetching: false });
            }
        };
        fetchPlayers();
    }, []);

    const filterData = event => {
        setSerarchFilter(event.target.value);
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Typography color="textPrimary">Players</Typography>
            </Breadcrumbs>
            <Paper className={classes.root}>
                <Toolbar searchChange={filterData} tableName="players"/>
                <div className={classes.content}>
                    <PlayersTable players={data.players} filter={searchFilter} />
                </div>
            </Paper>
        </div>
    );
};

export default Players;
