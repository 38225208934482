import React, { useState, useContext } from 'react';
// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
// Testelope
import InputComponentsMap from './InputComponentsMap';
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    table: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderTop: '0px solid grey',
        tableLayout: 'fixed',
        width: '180px'
    },
    cell: {
        margin: 0,
        border: '1px solid grey',
        whiteSpace: 'nowrap',
        borderBottomWidth: '0px',
        height: '22px',
        width: '50px',
        textAlign: 'center',
        padding: '3px',
        fontWeight: 'initial',
        '& input': {
            width: '40px',
            height: '22px',
            textAlign: 'right',
            color: '#000',
            borderWidth: '0px',
            background: 'inherit'
        }
    },
    emptyCell: {
        height: '22px',
    },
    column0: {
        backgroundColor: '#eee'
    },
    column1: {},
    div: {
        overflowX: 'scroll',
        marginLeft: '12em',
        overflowY: 'visible',
        padding: 0,
    },
    headcol: {
        position: 'absolute',
        marginLeft: '-12em',
        maxWidth: '12em',
        top: 'auto',
        borderTopWidth: '1px',
        /*only relevant for first row*/
        marginRop: '-1px',
        padding: '3px',
        /*compensate for top border*/
        display: 'table-column',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'noWrap'
    },
    lastHeadCol: {
        borderBottom: '2px solid grey'
    },
    verticalTableHeader: {
        height: '200px',
        whiteSpace: 'nowrap',
        border: 0,
        '& div': {
            transform: 'translate(15px, 80px) rotate(315deg)',
            width: '30px',

            '& span': {
                borderBottom: '1px solid #ccc',
                padding: '5px 10px'
            }
        }
    },
    notSaved: {
        backgroundColor: "#f99"
    },
    notNormalized: {
        backgroundColor: "#FF0"
    },
    filterTextboxes: {
        height: "200px",
        paddingTop: "155px"
    },
    defaultCursor: {
        cursor: 'default'
    }
}));

const OccasionOverviewTable = props => {

    const classes = useStyles();
    const { strings } = useContext(LanguageContext);
    const [testSearchFilter, setTestSearchFilter] = useState('');
    const [userSearchFilter, setUserSearchFilter] = useState('');


    const FilterTests = (tests) => {
        if (tests !== undefined) {
            return tests.filter((t) => {
                return t.name.toLowerCase().indexOf(testSearchFilter.toLowerCase()) !== -1
            });
        }
    }
    const FilterUsers = (users) => {
        if (users !== undefined) {
            return users.filter((u) => {
                return (u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(userSearchFilter.toLowerCase()) !== -1;
            });
        }
    }
    const GetStatsValue = (instructionId, propertyName) => {
        let occasionInsructionStats = props.teamStatistics.find(s => s.instructionId === instructionId && s.occasionId === props.occasion.id);
        if (occasionInsructionStats) {
            return occasionInsructionStats[propertyName];
        }
        return null;
    }

    const GetInstructionStats = (instructionId) => {
        let occasionInsructionStats = props.teamStatistics.find(s => s.instructionId === instructionId && s.occasionId === props.occasion.id);
        return occasionInsructionStats ?? null;
    }

    const GetResult = (instructionId, userId) => {
        let result = props.results.find(r => r.userId === userId &&
            r.instructionId === instructionId && r.occasionId === props.occasion.id);

        return result;
    }

    const metricDisplayName = (metric) => {
        if (metric === 'maximum') {
            return `${strings.max} / #${strings.pass}`;
        }
        if (metric === 'minimum') {
            return `${strings.min} / #${strings.fail}`;
        }
        if (metric === 'average') {
            return `${strings.average} / % ${strings.pass}`;
        }
        if (metric === 'median') {
            return strings.median;
        }
    }


    return (
        <>
            <div className={classes.div}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.headcol + " " + classes.filterTextboxes}>
                                <input type="text" placeholder={strings.filterTests} onChange={(e) => setTestSearchFilter(e.target.value)}></input>
                                <br></br>
                                <input type="text" placeholder={strings.filterPlayers} onChange={(e) => setUserSearchFilter(e.target.value)}></input>
                            </th>
                            <td className={classes.verticalTableHeader}></td>
                            {FilterTests(props.instructions).map((instruction, index) => (
                                <Tooltip className={classes.defaultCursor} key={instruction.id} title={instruction.description} arrow>
                                    <th key={instruction.id} className={classes.cell + " " + classes.verticalTableHeader}>
                                        <div>
                                            <span>{instruction.name}</span>
                                        </div>
                                    </th>
                                </Tooltip>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {['maximum', 'minimum', 'average'].map(metric => {
                            return (
                                <tr key={metric}>
                                    <th className={classes.headcol}>{metricDisplayName(metric)}</th>
                                    <th className={classes.emptyCell}></th>
                                    {FilterTests(props.instructions).map((instruction, index) => {
                                        const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];
                                        const classNames = `${classes.cell} ${classes["column" + (index % 2)]} ${metric === 'median' && classes.lastHeadCol}`
                                        return (
                                            <th key={instruction.id + ":" + props.occasion.id} className={classNames}>
                                                <InputComponent value={GetStatsValue(instruction.id, metric)}
                                                    readOnly={true}
                                                    excel={true}
                                                    statistic={true}
                                                    statistics={GetInstructionStats(instruction.id)} metric={metric}
                                                    instruction={instruction} ></InputComponent>
                                            </th>
                                        )
                                    }
                                    )}
                                </tr>
                            );
                        })}
                        {FilterUsers(props.teamMembers).map((teamMember, userIndex) => (
                            <tr key={teamMember.user.id}>
                                <td className={classes.headcol} textoverflow="ellipsis">{teamMember.user.firstName} {teamMember.user.lastName}</td>
                                <td className={classes.emptyCell}></td>
                                {FilterTests(props.instructions).map((instruction, index) => {
                                    const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];
                                    let result = GetResult(instruction.id, teamMember.user.id);
                                    let notSaved = result && result.saved === false;
                                    let notNormalized = result && result.value !== null && result.value !== '' && result.normalizedValue === null;
                                    let notSavedClass = (notSaved ? " " + classes.notSaved : "");
                                    let notNormalizedClass = (notNormalized ? " " + classes.notNormalized : "");
                                    let toolTip = (notNormalized ? strings.noValidValue : "") + (notSaved ? strings.notSaved : "");

                                    if (instruction.valueFormatRegex && instruction.valueFormatMessage && result && result.value) {
                                        if (!RegExp(instruction.valueFormatRegex).test(result.value)) {
                                            toolTip = instruction.valueFormatMessage;
                                        }
                                    }

                                    return (
                                        <Tooltip key={instruction.id + ":" + props.occasion.id} title={toolTip} arrow>
                                            <td key={instruction.id + ":" + props.occasion.id}
                                                className={classes.cell + " " + classes["column" + (index % 2)] + notSavedClass + notNormalizedClass}
                                            >
                                                <InputComponent value={result ? result.value : null}
                                                    normalizedValue={result ? result.normalizedValue : null}
                                                    updateValue={props.setResultValue}
                                                    userId={teamMember.user.id}
                                                    occasionId={props.occasion.id}
                                                    instructionId={instruction.id}
                                                    excel={true}
                                                    tabIndex={(index + 1) * 1000 + userIndex}
                                                    saveSingleResult={props.saveSingleResult}
                                                    instruction={instruction} />
                                            </td>
                                        </Tooltip>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Button onClick={props.saveAllResults}
                color="primary"
                size="large"
                variant="contained">
                {strings.save}
            </Button>
        </>
    )
};

export default OccasionOverviewTable;