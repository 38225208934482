import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: '#4e89ae',
    color: theme.palette.primary.contrastText,
    height: 86,
    width: 86
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const TestCompletion = props => {
  const { className, totalTestCompletion, ...rest } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <CardContent>
        <Grid
          container
          justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="subtitle1">
              {strings.testCompletion}
            </Typography>
            <Typography variant="h3">{totalTestCompletion.toFixed(2)}%</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={totalTestCompletion ?? 0}
          variant="determinate"
        />
      </CardContent>
    </Card>
  );
};

TestCompletion.propTypes = {
  className: PropTypes.string,
  totalTestCompletion: PropTypes.number
};

export default TestCompletion;
