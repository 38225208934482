import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f538',
    margin: '.4rem',
    width: '48%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const TestStatsCard = props => {

  const classes = useStyles();

  const GetStatisticsValue = (occasionId, attributeName, decimals) => {
    try {

      const occasionStatistics = props.statistics.find(s => s.occasionId === occasionId);
      if (!occasionStatistics) {
        return "N/A";
      }
      return occasionStatistics[attributeName].toFixed(decimals);
    }
    catch (e) {
      console.log(e);
      return "XX";
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.instruction.name.substring(0, 2)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.instruction.name}
        subheader={'Measured in ' + props.instruction.unit}
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {props.occasions.map((occasion, index) => (
                  <TableCell key={occasion.id}>{occasion.name.toUpperCase()}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Count</TableCell>
                {props.occasions.map((occasion, columnIndex) => (
                  <TableCell key={columnIndex}>{GetStatisticsValue(occasion.id, "count", 0)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Max</TableCell>
                {props.occasions.map((occasion, columnIndex) => (
                  <TableCell key={columnIndex}>{GetStatisticsValue(occasion.id, "maximum", 2)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Min</TableCell>
                {props.occasions.map((occasion, columnIndex) => (
                  <TableCell key={columnIndex}>{GetStatisticsValue(occasion.id, "minimum", 2)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Average</TableCell>
                {props.occasions.map((occasion, columnIndex) => (
                  <TableCell key={columnIndex}>{GetStatisticsValue(occasion.id, "average", 2)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Median</TableCell>
                {props.occasions.map((occasion, columnIndex) => (
                  <TableCell key={columnIndex}>{GetStatisticsValue(occasion.id, "median", 2)}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
export default TestStatsCard;