import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Testelope
import { useApi } from 'hooks';
import TeamsTable from './components/TeamsTable'
import { TeamsToolbar } from 'components'
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    breadcrumbs: {
        margin: '1rem'
    },
    loading: {
        paddingTop: '2rem',
        paddingBottom: '1rem',
        textAlign: 'center'
    }
}));

const Teams = () => {
    const { callApi } = useApi();
    const classes = useStyles();
    const history = useHistory();
    const { strings } = useContext(LanguageContext);

    const [data, setData] = useState({ teams: [], isFetching: false });
    const [searchFilter, setSearchFilter] = useState('');
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [statusSnackbar, setStatusSnackbar] = useState({
        open: false,
        message: ''
    });

    useEffect(() => {

        const fetchTeams = async () => {
            try {
                setData({ teams: data.teams, isFetching: true });
                const response = await callApi('/teams', 'get', null, null);
                setData({ teams: response.teams, isFetching: false });
            } catch (e) {
                console.log(e);
                setData({ teams: data.teams, isFetching: false });
            }
        };

        const fetchProfiles = async () => {
            try {
                const response = await callApi('/profiles', 'get', null, null);
                setProfiles(response.profiles);
            } catch (e) {
                console.log('Failed to get profiles.', e);
            }
        };

        Promise.all([fetchTeams(), fetchProfiles()]).then(() => setIsLoading(false));
    }, []);

    const handleOpenStatusSnackbar = (message) => {
        setStatusSnackbar({ open: true, message: message.toString() });
    };

    const handleCloseStatusSnackbar = () => {
        setStatusSnackbar({ open: false, message: '' });
    };

    const saveNewTeam = async (newTeamForm) => {
        let newTeam = {
            name: newTeamForm.teamName,
            description: newTeamForm.teamDescription,
            tags: [
                { name: 'startingYear', value: newTeamForm.startingYear },
                { name: 'ageGroup', value: newTeamForm.ageGroup }
            ],
            profileId: parseInt(newTeamForm.profileId)
        };

        setData({ teams: data.teams, isFetching: true });

        try {
            const response = await callApi('/teams', 'post', newTeam, null);

            if (response === undefined) {
                return 'error';
            }
            
            let teamsCopy = data.teams.map(t => t);
            teamsCopy.push(response.team);

            setData({ teams: teamsCopy, isFetching: false });
            return viewTeam(response.team.id);
        }
        catch {
            return 'error';
        }

    };

    const deleteTeam = async (team) => {
        let teamsCopy = data.teams.map(t => t);

        const response = await callApi('/teams/' + team.id + '/delete', 'post', null, null);
        if (response.removed === true) {

            var index = teamsCopy.findIndex((t => t.id === team.id));
            teamsCopy.splice(index, 1)
        }

        handleOpenStatusSnackbar(`${team.name} ${strings.hasBeenDeleted}`);
        setData({ teams: teamsCopy, isFetching: false });
    }

    const filterData = value => {
        setSearchFilter(value);
    }

    const viewTeam = (id) => {
        const path = '/teams/' + id;
        history.push(path);
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Typography variant="h5" color="textPrimary">{strings.myTeams}</Typography>
            </Breadcrumbs>
            <Paper className={classes.root}>
                <TeamsToolbar searchChange={filterData} tableName={strings.team} saveNewTeam={saveNewTeam} profiles={profiles} />
                <div className={classes.content}>
                    {
                        isLoading ?
                            <div className={classes.loading}>
                                <CircularProgress color="inherit" />
                            </div> :
                            <TeamsTable teams={data.teams} filter={searchFilter} deleteTeam={deleteTeam} />
                    }
                </div>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={statusSnackbar.open}
                onClose={handleCloseStatusSnackbar}
                autoHideDuration={5000}
                message={statusSnackbar.message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseStatusSnackbar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default Teams;
