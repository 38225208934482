import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Testelope
import { useApi } from 'hooks';
import { PlayersToolbar } from 'components'
import { TeamAvatar, TeamPlayers, UserResultsOverview, TeamManagers } from './components';
import TeamDetailsExcelVersion from 'Pages/TeamDetailsExcelVersion';
import { LanguageContext } from 'context';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    content: {
        marginTop: theme.spacing(2)
    },
    tabs: {
        paddingTop: theme.spacing(2)
    },
    breadcrumbs: {
        margin: '1rem'
    },
    tile: {
        height: 'fit-content !Important',
        marginBottom: '2rem'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    errorMessage: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    printIframe: {
        display: 'none'
    },
    userResultsOverview: {
        width: '1000px'
    }
}));

const TeamDetails = props => {

    const { callApi } = useApi();
    const { strings } = useContext(LanguageContext);
    const classes = useStyles();
    const history = useHistory();
    const [team, setTeam] = useState({});
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamManagers, setTeamManagers] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [instructions, setInstructions] = useState([]);
    const [results, setResults] = useState([]);
    const [teamStatistics, setTeamStatistics] = useState([]);
    const [profileStatistics, setProfileStatistics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [failedToLoadTeam, setFailedToLoadTeam] = useState(null);
    const [searchFilter, setSearchFilter] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [statusSnackbar, setStatusSnackbar] = useState({
        open: false,
        message: ''
    });
    const [userOverview, setUserOverview] = useState({
        user: {},
        instructions: {},
        results: {}
    });
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {

        const fetchTeam = async () => {
            try {
                setIsLoading(true);
                let promises = [];
                // Need to get the profile first since it is used to build urls to get other info... TODO: Allow to choose profile - do not get from this response
                const profilesResponse = await callApi('/teams/' + props.match.params.teamId + '/profiles', 'get', null, null);

                promises.push(callApi('/teams/' + props.match.params.teamId + '', 'get', null, null));
                promises.push(callApi('/teams/' + props.match.params.teamId + '/teamMembers', 'get', null, null));
                promises.push(callApi('/teams/' + props.match.params.teamId + '/teamManagers', 'get', null, null));
                // TODO: Add support to select profile to enable use of different profiles for the team
                promises.push(callApi('/profiles/' + profilesResponse.profiles[0].id + '/occasions', 'get', null, null));
                // TODO: Enable selection of profile - now it picks the first one found...
                promises.push(callApi('/profiles/' + profilesResponse.profiles[0].id + '/instructions', 'get', null, null));
                // TODO: Enable selection of profile - now it picks the first one found...
                promises.push(callApi('/teams/' + props.match.params.teamId + '/profiles/' + profilesResponse.profiles[0].id + '/results', 'get', null, null));
                // TODO: Enable selection of profile - now it picks the first one found...
                promises.push(callApi('/teams/' + props.match.params.teamId + '/profiles/' + profilesResponse.profiles[0].id + '/statistics', 'get', null, null));
                // TODO: Enable selection of profile - now it picks the first one found...
                promises.push(callApi('/profiles/' + profilesResponse.profiles[0].id + '/statistics', 'get', null, null));

                const [ teamResponse,
                        teamMembersResponse,
                        teamManagersResponse,
                        occasionsResponse,
                        instructionsResponse,
                        resutlsResponse,
                        teamStatisticsResponse,
                        profileStatisticsResponse] = await Promise.all(promises);

                setTeam(teamResponse.team);
                setTeamMembers(teamMembersResponse.teamMembers);
                setTeamManagers(teamManagersResponse.teamManagers);
                setProfiles(profilesResponse.profiles);
                setOccasions(occasionsResponse.occasions);
                setInstructions(instructionsResponse.instructions);
                setResults(resutlsResponse.results);
                setTeamStatistics(teamStatisticsResponse.statistics);
                setProfileStatistics(profileStatisticsResponse.statistics);

                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setFailedToLoadTeam(e.message);
                setIsLoading(false);
            }
        };
        fetchTeam();



    }, []);

    const filterData = value => {
        setSearchFilter(value);
    }

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const saveAllResults = async () => {
        setIsSaving(true);

        const updatedResults = results.filter((result) => {
            return result.saved === false;
        });
        const response = await callApi('/results/save', 'put', updatedResults);

        let resultsCopy = results.map((result) => {
            if (result.saved === false) {
                return response.results.find(r => r.userId === result.userId &&
                    r.occasionId === result.occasionId &&
                    r.instructionId === result.instructionId);
            }
            return result;
        });

        setResults(resultsCopy);
        await reLoadStatistics();
        setIsSaving(false);
    };


    const saveResult = async (userId, occasionId, instructionId) => {
        return; // WIP for auto save result on blur. overwrites state when saving multiple values in a short time.

        let result = results.find(r => r.userId === userId &&
            r.occasionId === occasionId &&
            r.instructionId === instructionId);
        if (result === undefined) {
            return;
        }

        const response = await sendResultToApi(result);

        let resultsCopy = results.map(r => r);
        let updatedResult = resultsCopy.find(r => r.userId === userId &&
            r.occasionId === occasionId &&
            r.instructionId === instructionId);

        updatedResult.id = response.id;
        updatedResult.saved = true;
        updatedResult.value = response.value;
        updatedResult.normalizedValue = response.normalizedValue;

        setResults(resultsCopy);
    };

    const sendResultToApi = async (result) => {
        if (result.saved !== false) {
            return result;
        }
        let address = '/results/';
        let method = 'put';
        if (result.id) {
            address += result.id;
            method = 'post';
        }
        const response = await callApi(address, method, result);
        return response.result;
    }

    const reLoadStatistics = async () => {
        // TODO: Enable selection of profile - now it picks the first one found...
        const response = await callApi('/teams/' + team.id + '/profiles/' + profiles[0].id + '/statistics', 'get');
        setTeamStatistics(response.statistics);

        // TODO: Enable selection of profile - now it picks the first one found...
        const profileStatisticsResponse = await callApi('/profiles/' + profiles[0].id + '/statistics', 'get', null, null);
        setProfileStatistics(profileStatisticsResponse.statistics);
    }

    const setResultValue = (userId, occasionId, instructionId, value, normalizedValue) => {
        let resultsCopy = results.map(r => r);
        let updatedResult = resultsCopy.find(r => r.userId === userId &&
            r.occasionId === occasionId &&
            r.instructionId === instructionId);

        // No existing result to update - push new
        if (!updatedResult) {
            updatedResult = {
                userId: userId,
                occasionId: occasionId,
                instructionId: instructionId
            }
            resultsCopy.push(updatedResult);
        }

        updatedResult.saved = false;
        updatedResult.normalizedValue = parseFloat(normalizedValue);
        updatedResult.value = value != null ? value.toString() : null;
        setResults(resultsCopy);

        if (results.filter(r => r.saved === false).length % 5 == 0) {
            handleOpenStatusSnackbar(strings.rememberToSave);
        }
    };

    const addNewPlayer = async (newPlayer) => {
        let teamMembersCopy = teamMembers.map(t => t);

        const teamMemberDto = {
            teamMember: {
                user: {
                    firstName: newPlayer.playerFirstName,
                    lastName: newPlayer.playerLastName,
                    email: newPlayer.playerEmail,
                    picture: newPlayer.playerAvatar
                }
            }
        }
        const response = await callApi('/teams/' + team.id + '/TeamMembers', 'post', teamMemberDto, null);

        teamMembersCopy.push(response.teamMember);

        handleOpenStatusSnackbar(`Player ${response.teamMember.user.firstName} ${strings.haveBeenAdded}`);

        setTeamMembers(teamMembersCopy);
    };

    const addTeamManager = async (newTeamManager) => {
        if (teamManagers.find(tm => tm.user.id === newTeamManager.id)) {
            handleOpenStatusSnackbar(`${newTeamManager.firstName || ''} ${newTeamManager.lastName || ''} is already manager of the team`);
            return;
        }

        let teamManagersCopy = teamManagers.map(tm => tm);
        await callApi('/teams/' + team.id + '/teamManagers/' + newTeamManager.id, 'put', {});
        teamManagersCopy.push({ user: newTeamManager });
        setTeamManagers(teamManagersCopy);

        handleOpenStatusSnackbar(`${newTeamManager.firstName || ''} ${newTeamManager.lastName || ''} has been added as team manager`);
    };

    const removeTeamManager = async (removeTeamManager) => {
        if (teamManagers.length === 1) {
            handleOpenStatusSnackbar(`Cannot remove manager - at least one team manager must be set for the team`);
            return;
        }
        await callApi('/teams/' + team.id + '/teamManagers/' + removeTeamManager.id, 'delete');
        let teamManagersCopy = teamManagers.filter(tm => {
            if (tm.user.id === removeTeamManager.id) {
                return false;
            }
            return true;
        })
            .map(tm => tm);
        setTeamManagers(teamManagersCopy);

        handleOpenStatusSnackbar(`${removeTeamManager.firstName || ''} ${removeTeamManager.lastName || ''} has been removed as team manager`);
    };

    const editPlayer = async (player) => {
        let teamMembersCopy = teamMembers.map(t => t);

        const teamMemberDto = {
            teamMember: {
                user: {
                    id: player.playerId,
                    firstName: player.playerFirstName,
                    lastName: player.playerLastName,
                    email: player.playerEmail,
                    picture: player.playerAvatar
                }
            }
        }

        const response = await callApi('/teams/' + team.id + '/TeamMembers/' + player.playerId, 'post', teamMemberDto, null);

        const index = teamMembersCopy.findIndex((m => m.user.id === response.teamMember.user.id));
        teamMembersCopy[index] = response.teamMember;

        handleOpenStatusSnackbar(`${response.teamMember.user.firstName} ${strings.hasBeenUpdated}`);

        setTeamMembers(teamMembersCopy);
    };

    const deletePlayer = async (player) => {
        let teamMembersCopy = teamMembers.map(t => t);

        const response = await callApi('/teams/' + team.id + '/TeamMembers/' + player.playerId + '/delete', 'post', null, null);
        if (response.removed === true) {

            var index = teamMembersCopy.findIndex((m => m.id === player.playerId));
            teamMembersCopy.splice(index, 1)
        }

        handleOpenStatusSnackbar(`${player.playerFirstName} ${strings.hasBeenDeleted}`);

        setTeamMembers(teamMembersCopy);
    };

    const editTeam = async (teamForm) => {
        let teamDto = {
            teamId: teamForm.teamId,
            name: teamForm.teamName,
            description: teamForm.teamDescription,
            picture: teamForm.teamAvatar,
            tags: [
                { name: 'startingYear', value: teamForm.teamStartingYear },
                { name: 'ageGroup', value: teamForm.teamAgeGroup }
            ]
        }
        try {
            const response = await callApi('/teams/' + teamForm.teamId, 'post', teamDto, null);
            handleOpenStatusSnackbar(`${response.team.name} ${strings.hasBeenUpdated}`);
            setTeam(response.team);
        } catch (error) {
            return error;
        }
    };

    const handleOpenStatusSnackbar = (message) => {
        setStatusSnackbar({ open: true, message: message.toString() });
    };

    const handleCloseStatusSnackbar = () => {
        setStatusSnackbar({ open: false, message: '' });
    };

    const ShowUserOverview = (teamMember) => {
        setUserOverview({
            user: teamMember.user,
            results: results.filter(r => r.userId === teamMember.user.id)
        });
        setShowUserInfoModal(true);
    };

    const printUserInfo = (user) => {
        let userInfo = `<div style="margin-bottom:10px">${strings.player}: <strong>${user.firstName} ${user.lastName}</strong></br> ${strings.emailAddress}: <strong>${user.email}</strong></div>`;
        let userResultsOverviewDiv = document.getElementById('userResultsOverview');
        const printContent = userResultsOverviewDiv.innerHTML;

        let printMe = document.getElementById('printMe');
        printMe.contentWindow.document.open();
        printMe.contentWindow.document.write(userInfo + printContent);
        printMe.contentWindow.document.close();

        printMe.contentWindow.document.head.innerHTML = document.head.innerHTML;
        printMe.focus();
        printMe.contentWindow.print();
    };

    const closeUserInfo = () => {
        setShowUserInfoModal(false);
    }

    const totalTestCompletion = () => {
        const instructionOccasionCount = occasions.reduce((total, o) => {
            return total + o.instructionIds.length;
        }, 0);
        const possibleResultsCount = instructionOccasionCount * teamMembers.length;

        return results.length ? (results.length / possibleResultsCount * 100) : 0;
    }

    if (isLoading) {
        return (
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

    if (failedToLoadTeam) {
        return (
            <div className={classes.root}>
                <Paper className={classes.errorMessage}>
                    <span>Failed to load team information. Error: {failedToLoadTeam}</span>
                </Paper>
            </div>
        )
    }

    return (
        <>
            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Typography variant="h5" color="textPrimary">
                        <Link color="inherit" href="#" onClick={() => {
                            history.push('/teams');
                        }}> {strings.myTeams}
                        </Link>
                    </Typography>
                    <Typography variant="h5" color="textPrimary">{team.name}</Typography>
                </Breadcrumbs>
                <Paper>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        className={classes.tabs}>
                        <Tab label={team.name} />
                        {/* <Tab label="Tests" /> */}
                        {/* <Tab label="Results" /> */}
                        <Tab label={strings.managers} />
                        <Tab label={strings.players} />
                        <Tab label={strings.tests} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <TeamAvatar team={team} teamMembers={teamMembers} totalTestCompletionProcentage={totalTestCompletion()} editTeam={editTeam} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={classes.content}>
                            <TeamManagers managers={teamManagers} addManager={addTeamManager} removeManager={removeTeamManager}></TeamManagers>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <PlayersToolbar searchChange={filterData} tableName={strings.players} addNewPlayer={addNewPlayer} />
                        <div className={classes.content}>
                            <TeamPlayers
                                users={teamMembers}
                                editPlayer={editPlayer}
                                deletePlayer={deletePlayer}
                                filter={searchFilter}
                                showUserOverview={ShowUserOverview} >
                            </TeamPlayers>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <TeamDetailsExcelVersion 
                            profileId={profiles[0].id} // TODO: Enable selecting profile...
                            occasions={occasions}
                            instructions={instructions}
                            results={results}
                            teamMembers={teamMembers}
                            teamStatistics={teamStatistics}
                            profileStatistics={profileStatistics}
                            teamId={team.id}
                            setResults={setResultValue}
                            saveAllResults={saveAllResults}
                            saveSingleResult={saveResult}>
                        </TeamDetailsExcelVersion>
                    </TabPanel>
                </Paper>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={statusSnackbar.open}
                    onClose={handleCloseStatusSnackbar}
                    autoHideDuration={5000}
                    message={statusSnackbar.message}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseStatusSnackbar}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>
            <iframe title='print' src='about:blank' id='printMe' className={classes.printIframe}></iframe>
            <Dialog
                fullScreen={fullScreen}
                open={showUserInfoModal}
                onClose={closeUserInfo}
                maxWidth='lg'>
                <DialogTitle id="alert-dialog-title">
                    {userOverview.user.firstName + ' ' + userOverview.user.lastName}
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <div id='userResultsOverview' >
                            <UserResultsOverview
                                user={userOverview.user}
                                results={userOverview.results}
                                instructions={instructions}
                                teamStatistics={teamStatistics}
                                profileStatistics={profileStatistics}
                                occasions={occasions} />
                        </div>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeUserInfo} color="default">
                        {strings.close}
                    </Button>
                    <Button onClick={() => printUserInfo(userOverview.user)} color="primary" autoFocus>
                        {strings.print}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TeamDetails;
