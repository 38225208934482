import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControlAges:{
        display: 'flex',
        flexGrow: 1,
        placeContent: 'space-between',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    },
    formTextfields:{
        placeContent: 'space-between',
    }
}));

const PlayersDialog = props => {

    const { player, savePlayer } = props;

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [playerForm, setPlayerForm] = React.useState({
        playerFirstName: player.firstName,
        playerLastName: player.lastName,
        playerEmail: player.email
    })

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    
    const handleDialogSubmit = (player) => {
        savePlayer(player);
        setDialogOpen(false);
    };

    const handleNewPlayerFormChange = (event) => {
        const name = event.target.name;
        setPlayerForm({
            ...playerForm,
            [name]: event.target.value,
        });
    };

    const handleClear = () => {
        setPlayerForm({
            playerFirstName: '',
            playerLastName: '',
            playerEmail: '',
            playerBirthDay: ''
        })
    };

    return (
            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen} 
                onClose={handleDialogClose} 
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit {playerForm.playerFirstName}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    To create a new player, please fill in the fields below and press the Add button. You can edit player settings in the player details later.
                </DialogContentText>
                <div className={classes.formControlAges}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="playerFirstName"
                    label="Firstname"
                    type="email"
                    style={{minWidth: '45%'}}
                    value={playerForm.playerFirstName}
                    inputProps={{
                        name: 'playerFirstName',
                        id: 'age-native-simple',
                    }}
                    onChange={handleNewPlayerFormChange} />
                <TextField
                    margin="dense"
                    id="playerLastName"
                    label="Lastname"
                    type="email"
                    style={{minWidth: '45%'}}
                    value={playerForm.playerLastName}
                    inputProps={{
                        name: 'playerLastName',
                        id: 'age-native-simple',
                    }}
                    onChange={handleNewPlayerFormChange} />
                </div>
                <TextField
                    margin="dense"
                    id="playerEmail"
                    label="Email"
                    type="email"
                    fullWidth
                    value={playerForm.playerEmail}
                    inputProps={{
                        name: 'playerEmail',
                        id: 'age-native-simple',
                    }}
                    onChange={handleNewPlayerFormChange}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClear} color="default">
                    Clear
                </Button>
                <Button onClick={handleDialogClose} color="default">
                    Close
                </Button>
                <Button onClick={() => handleDialogSubmit(playerForm)} color="primary">
                    Save player
                </Button>
                </DialogActions>
            </Dialog>
    );
};

PlayersDialog.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default PlayersDialog;
