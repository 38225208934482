import React, {useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { SearchInput } from 'components';
import { LanguageContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControlAges: {
        display: 'flex',
        flexGrow: 1,
        paddingTop: '1rem',
        paddingBottom: '1rem',
        whiteSpace: 'nowrap'
    },
    formTextfields: {
        placeContent: 'space-between',
    },
    error:{
        color: 'Red'
    }
}));

const TeamsToolbar = props => {

    const year = (new Date()).getFullYear();
    const ageGroupYears = Array.from(new Array(50), (val, index) => year - index);
    const startingYears = Array.from(new Array(15), (val, index) => index + year - 5);

    const { className, searchChange, tableName, saveNewTeam, profiles, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { strings } = useContext(LanguageContext);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [newTeamForm, setNewTeamForm] = useState({
        teamName: '',
        teamDescription: '',
        ageGroup: `${year - 16}`,
        startingYear: `${year}`,
        profileId: null
    });
    const [errors, setErrors] = useState({
        teamNameError: null
    });

    useEffect(() => {  
        profiles.length && setNewTeamForm({...newTeamForm, profileId: profiles[0].id});
    }, [profiles]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogSubmit = async (team) => {
        if (!team.teamName){
            return;
        }
        const result = await saveNewTeam(team);
        
        if(result === 'error'){
            setErrors({teamNameError: strings.teamExistsError})
        } else {
            setDialogOpen(false);
        }
    };

    const handleNewTeamFormChange = (event) => {
        const name = event.target.name;
        setNewTeamForm({
            ...newTeamForm,
            [name]: event.target.value,
        });
    };

    const handleClear = () => {
        setNewTeamForm({
            teamName: '',
            teamDescription: '',
            ageGroup: '',
            startingYear: '',
            profileId: profiles[0].id
        })
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <div className={classes.spacer}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder={`${strings.search} ${tableName}`}
                        onChange={searchChange} />
                </div>
                <span className={classes.spacer} />
                <div>
                    <Button variant="outlined" color="primary" onClick={handleDialogOpen}>
                        {strings.addNewTeam}
                    </Button>
                    <Dialog
                        fullScreen={fullScreen}
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            {strings.addNewTeam}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {strings.addNewTeamDescription}
                             </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label={strings.teamName}
                                type="name"
                                fullWidth
                                value={newTeamForm.teamName}
                                inputProps={{
                                    name: 'teamName',
                                    id: 'age-native-simple',
                                }}
                                onChange={handleNewTeamFormChange} 
                                required/>
                            <div className={classes.error}>
                                {errors.teamNameError}
                            </div>
                            <TextField
                                margin="dense"
                                id="description"
                                label={strings.teamDescription}
                                type="description"
                                multiline
                                fullWidth
                                value={newTeamForm.teamDescription}
                                inputProps={{
                                    name: 'teamDescription',
                                    id: 'age-native-simple',
                                }}
                                onChange={handleNewTeamFormChange} />
                            <div className={classes.formControlAges}>
                                <FormControl className={classes.formControl}  style={{minWidth: '49%', marginRight: '1%'}}>
                                    <InputLabel htmlFor="age-native-simple">{strings.ageGroup}</InputLabel>
                                    <Select
                                        native
                                        value={newTeamForm.ageGroup}
                                        onChange={handleNewTeamFormChange}
                                        inputProps={{
                                            name: 'ageGroup',
                                            id: 'age-native-simple',
                                        }} >
                                        <option aria-label="None" value="" />
                                        {ageGroupYears.map((year, index) => {
                                            return <option key={`year${index}`} value={year}>{year}</option>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}  style={{minWidth: '49%', marginLeft: '1%'}}>
                                    <InputLabel htmlFor="age-native-simple">{strings.startingYear}</InputLabel>
                                    <Select
                                        native
                                        value={newTeamForm.startingYear}
                                        onChange={handleNewTeamFormChange}
                                        inputProps={{
                                            name: 'startingYear',
                                            id: 'age-native-simple',
                                        }} >
                                        <option aria-label="None" value="" />
                                        {startingYears.map((year, index) => {
                                            return <option key={`year${index}`} value={year}>{year}</option>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.formControlAges}>
                                <FormControl className={classes.formControl} style={{minWidth: '49%'}}>
                                    <InputLabel htmlFor="profileId-native-simple">{strings.testProfile}</InputLabel>
                                    <Select
                                        native
                                        value={newTeamForm.profileId}
                                        onChange={handleNewTeamFormChange}
                                        inputProps={{
                                            name: 'profileId',
                                            id: 'profileId-native-simple',
                                        }}
                                        required={true} >
                                        {profiles.map((profile) => {
                                            return <option key={profile.id} value={profile.id}>{profile.name}</option>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClear} color="default">
                                {strings.clear}
                            </Button>
                            <Button onClick={handleDialogClose} color="default">
                                {strings.close}
                            </Button>
                            <Button onClick={() => handleDialogSubmit(newTeamForm)} color="primary">
                                {strings.add}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

TeamsToolbar.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default TeamsToolbar;
