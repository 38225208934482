import React, { useState, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
// MaterialUI
import { makeStyles, useTheme } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { useMediaQuery } from '@material-ui/core';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutButton:{
    color: '#ffffff',
    paddingLeft: '2rem'
  }
}));

const HideOnScroll = props => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Topbar = props => {
  const { className, onSidebarOpen, forceSidebarClose, ...rest } = props;

  const theme = useTheme();
  const classes = useStyles();

  const [hidden, setHidden] = useState(true);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  useEffect(() => {
    setHidden(isDesktop && !forceSidebarClose)
  }, [hidden, isDesktop, forceSidebarClose]);

  const {
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
  logout({
    returnTo: window.location.origin,
  });

  return (
    <HideOnScroll>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}>
        <Toolbar>
          <RouterLink to="/overview">
            <img
              alt="Logo"
              src="/images/logos/testelope_logo.png"
              width="150px"
            />
          </RouterLink>
          <div className={classes.flexGrow} />
          {!hidden ?
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton> : "" }
            <Button
            className={classes.logoutButton}
            onClick={() => logoutWithRedirect()}
            startIcon={<LockRoundedIcon style={{ color: '#ffffff' }} />}>
            Logout
          </Button> 
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  history: PropTypes.any
};

export default Topbar;
