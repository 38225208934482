import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
// MaterialUI
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Button
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
// Testelope
import { getInitials } from 'helpers';
import { LanguageContext } from 'context';
import { sortAndDeduplicateDiagnostics } from 'typescript';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    tableRow: {
        cursor: 'pointer'
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    sortableHeader: {
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline-flex'
    }
}));

const TeamsTable = props => {
    const { className, teams, filter, deleteTeam, ...rest } = props;
    const { strings } = useContext(LanguageContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    const [selectedTeams, setSelectedTeams] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState({ id: 0, name: '' });

    const [sortingOptions, setSortingOptions] = useState({
        property: 'name',
        direction: 'asc'
    });

    const history = useHistory();

    const handleSelectAll = event => {
        const { teams } = props;
        let selectedTeams;

        if (event.target.checked) {
            selectedTeams = teams.map(team => team.id);
        } else {
            selectedTeams = [];
        }

        setSelectedTeams(selectedTeams);
    };

    const handleSelectOne = (event, id) => {

        const selectedIndex = selectedTeams.indexOf(id);
        let newSelectedTeams = [];

        if (selectedIndex === -1) {
            newSelectedTeams = newSelectedTeams.concat(selectedTeams, id);
        } else if (selectedIndex === 0) {
            newSelectedTeams = newSelectedTeams.concat(selectedTeams.slice(1));
        } else if (selectedIndex === selectedTeams.length - 1) {
            newSelectedTeams = newSelectedTeams.concat(selectedTeams.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedTeams = newSelectedTeams.concat(
                selectedTeams.slice(0, selectedIndex),
                selectedTeams.slice(selectedIndex + 1)
            );
        }

        setSelectedTeams(newSelectedTeams);
    };

    const handleCheckboxClick = (event) => {
        event.stopPropagation();
    }

    const viewTeam = (event, id) => {
        event.stopPropagation();
        const path = '/teams/' + id;
        history.push(path);
    }

    const handlePageChange = (event, page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const filterTeams = (teamList, searchWord) => {
        if (teamList !== undefined) {
            return teamList
                .sort((a, b) => {
                    if (sortingOptions.direction === 'asc') {
                        return a[sortingOptions.property] >= b[sortingOptions.property];
                    }
                    return a[sortingOptions.property] < b[sortingOptions.property];
                }).filter((t) => {
                    return t.name.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
                });
        }
    };

    const setSortingProperty = (propertyName) => {
        setSortingOptions({
            property: propertyName,
            direction: propertyName !== sortingOptions.property ? 'asc' :
                sortingOptions.direction === 'asc' ? 'desc' : 'asc'
        });
    }

    const sortIcon = () => {
        return sortingOptions.direction === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />
    }

    const handleDialogDeleteOpen = (event, team) => {
        event.stopPropagation();

        setTeamToDelete({
            id: team.id,
            name: team.name
        });

        setDialogDeleteOpen(true);
    };

    const handleDialogDeleteClose = () => {
        setDialogDeleteOpen(false);
    }

    const handleDialogDelete = (team) => {
        deleteTeam(team)
        setDialogDeleteOpen(false);
    }

    const GetTagValue = (team, tagName) => {
        if (team === null || team.tags === null || tagName === null) return '';
        const tag = team.tags.find(t => t.name === tagName);
        return (tag) ? tag.value : '';
    }

    if (teams.length === 0) {
        return (<div>
            <span>{strings.noTeamsFound}</span>
        </div>);
    }

    return (
        <div>
            <Card
                {...rest}
                className={clsx(classes.root, className)}>
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {/* Commented out until use */}
                                        {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedTeams.length === teams.length}
                                            color="primary"
                                            indeterminate={
                                                selectedTeams.length > 0 &&
                                                selectedTeams.length < teams.length
                                            }
                                            onChange={handleSelectAll}/>
                                    </TableCell> */}
                                        <TableCell onClick={() => setSortingProperty('name')} >
                                            <a className={classes.sortableHeader}>{strings.teamName}
                                            {sortingOptions.property === 'name' && sortIcon()}</a>
                                        </TableCell>
                                        <TableCell onClick={() => setSortingProperty('description')} >
                                            <a className={classes.sortableHeader}>{strings.teamDescription}
                                            {sortingOptions.property === 'description' && sortIcon()}</a>
                                        </TableCell>
                                        <TableCell align="right" style={{ width: '150px', minWidth: '50px', maxWidth: '150px' }}>{strings.ageGroup}</TableCell>
                                        <TableCell align="right" style={{ width: '150px', minWidth: '50px', maxWidth: '150px' }}>{strings.startingYear}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterTeams(teams, filter).slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(team => (
                                        <TableRow
                                            className={classes.tableRow}
                                            hover
                                            key={team.id}
                                            selected={selectedTeams.indexOf(team.id) !== -1}
                                            onClick={event => viewTeam(event, team.id)} >
                                            {/* Commented out until use */}
                                            {/* <TableCell padding="checkbox" className="excludeFromRowClick">
                                            <Checkbox
                                                checked={selectedTeams.indexOf(team.id) !== -1}
                                                color="primary"
                                                onChange={event => handleSelectOne(event, team.id)}
                                                value="true"
                                                onClick={event => handleCheckboxClick(event)}/>
                                        </TableCell> */}
                                            <TableCell>
                                                <div className={classes.nameContainer}>
                                                    <Avatar
                                                        className={classes.avatar}
                                                        src={team.picture}>
                                                        {getInitials(team.name)}
                                                    </Avatar>
                                                    <Typography variant="body1">{team.name}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>{team.description}</TableCell>
                                            <TableCell align="right" style={{ width: '150px', minWidth: '50px', maxWidth: '150px' }}>{GetTagValue(team, 'ageGroup')}</TableCell>
                                            <TableCell align="right" style={{ width: '150px', minWidth: '50px', maxWidth: '150px' }}>{GetTagValue(team, 'startingYear')}</TableCell>
                                            <TableCell style={{ width: 80 }} align="right" >
                                                <Tooltip title={strings.delete + ' ' + strings.team.toLowerCase()} arrow>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="Print" component="span"
                                                        onClick={event => handleDialogDeleteOpen(event, team)} >
                                                        <DeleteForeverIcon style={{ color: '#ed6663' }} fontSize='inherit' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                    <TablePagination
                        component="div"
                        count={filterTeams(teams, filter).length}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]} />
                </CardActions>
            </Card>
            {/* Delete Dialog */}
            <Dialog
                fullScreen={fullScreen}
                open={dialogDeleteOpen}
                onClose={handleDialogDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {strings.deleteTeamConfirmation}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {strings.deleteTeamConfirmationDesc} {teamToDelete.name}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteClose} color="default">
                        {strings.close}
                    </Button>
                    <Button onClick={() => handleDialogDelete(teamToDelete)} color="primary" autoFocus>
                        {strings.delete}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

TeamsTable.propTypes = {
    className: PropTypes.string,
    teams: PropTypes.array.isRequired,
    filter: PropTypes.string
};

export default TeamsTable;
