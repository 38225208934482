import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const FailPassInput = props => {

  const acceptedResults = [
    { label: "U", value: 0 },
    { label: "G", value: 1 },
    { label: "G-", value: 1 }
  ]

  const ValidationTextField = withStyles({
    root: {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important',
      },
    },
  })(TextField);

  const UpdateValue = (value) => {
    if (value === 'g') { value = 'G'; }
    if (value === 'u') { value = 'U'; }

    const normalizedValue = NormalizeValue(value);
    props.updateValue(props.userId, props.occasionId, props.instructionId, value, normalizedValue);
  };

  const NormalizeValue = (textValue) => {
    if (props.instruction.valueFormatRegex){
      const regexResult = RegExp(props.instruction.valueFormatRegex).exec(textValue);

      if (regexResult === null){
        return null;
      }
      if (regexResult.groups && regexResult.groups["value"]){
        textValue = regexResult.groups["value"]
      }
    }

    const result = acceptedResults.find(a => a.label === textValue.toUpperCase());
    if (result) {
      return result.value;
    }
    return null;
  };

  if (props.excel) {

    if (props.statistic) {
      if (!props.statistics || props.statistics['count'] === 0) { return ''; }

      const passCount = props.statistics['average'] * props.statistics['count'];
      const failCount = props.statistics['count'] - passCount;
      switch (props.metric) {
        case 'average':
          let percentPass = (props.statistics['average'] * 100).toFixed(0) + "%";
          return (
            <span>{percentPass}</span>
          );
        case 'maximum':
          return (
            <span>{passCount.toFixed(0)}</span>
          );
        case 'minimum':
          return (
            <span>{failCount.toFixed(0)}</span>
          );
          case 'median':
            return ('');
        default:
          const label = acceptedResults.find(r => r.value === props.value);
          return (
            <span>{label ? label.label : 'N/A'}</span>
          );
      }


    }

    return (
      <input type="text"
        value={props.value ?? ""}
        onChange={(e) => UpdateValue(e.target.value)}
        onClick={(e) => e.target.select()}
        disabled={props.readOnly}
        tabIndex={props.tabIndex}
        onBlur={() => props.saveSingleResult(props.userId, props.occasionId, props.instructionId)}></input>
    );
  }

  return (
    <ValidationTextField
      required
      // id="validation-outlined-input" 
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="end">Fail/Pass</InputAdornment>
      }}
      size="small"
      onChange={(e) => UpdateValue(e.target.value)}
      value={props.value} />
  );

}

export default FailPassInput;