import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { SearchInput, TransitionModal} from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const Toolbar = props => {

    const { className, searchChange, tableName, ...rest } = props;
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = () => {
        setOpenModal(true);
    };
  
    const handleModalClose = () => {
        setOpenModal(false);
    };

    const content = () => {
        return (
            <div>
                <p>tests</p>
            </div>
        )
    }
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <div className={classes.spacer}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder={"Search " + tableName}
                    onChange={searchChange}/>
                </div>
                <span className={classes.spacer} />
                <div>
                <Button className={classes.importButton}>Import</Button>
                <Button className={classes.exportButton}>Export</Button>
                <Button
                    color="primary"
                    onClick={handleModalOpen}
                    variant="contained">Add {tableName}
                </Button>
                <TransitionModal open={openModal} onClose={handleModalClose} content={content}></TransitionModal>
                </div>
            </div>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    searchChange: PropTypes.func,
    tableName: PropTypes.string
};

export default Toolbar;
