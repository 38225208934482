import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import InputComponentsMap from './InputComponentsMap';
import { useApi } from 'hooks';
import { Bar } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
    root: {

    },
    instructionNameRow: {
        backgroundColor: '#eee'
    },
}));

const TeamStatsComparison = props => {
    const { callApi } = useApi();
    const classes = useStyles();
    const { teamStatistics, profileStatistics, instructions, occasions, profileId, teamId, ...rest } = props;
    const [loading, setLoading] = useState(false);
    // const [profileNormalDistributionData, setProfileNormalDistributionData] = useState([]);
    // const [teamNormalDistributionData, setTeamNormalDistributionData] = useState([]);

    // useEffect(() => {
    //     const loadNormalDistributionData = async () => {
    //         setLoading(true);

    //         const profileDataPromise= callApi('/profiles/' + profileId + '/normalDistribution', 'get', null, null);
    //         const teamDataPromise = callApi('/teams/' + teamId + '/profiles/' + profileId + '/normalDistribution', 'get', null, null);

    //         const [profileDataResponse, teamDataResponse] =  await Promise.all([profileDataPromise, teamDataPromise]);

    //         setProfileNormalDistributionData(profileDataResponse.normalDistributionDataPoints);
    //         setTeamNormalDistributionData(teamDataResponse.normalDistributionDataPoints);

    //         setLoading(false);
    //     }
    //     loadNormalDistributionData();
    // }, []);

    const GetTeamStatistic = (instructionId, occasionId, metric) => {
        const stats = teamStatistics.find(s => s.instructionId === instructionId &&
            s.occasionId === occasionId);
        return stats ? stats[metric] : '';
    };
    const GetProfileStatistic = (instructionId, occasionId, metric) => {
        const stats = profileStatistics.find(s => s.instructionId === instructionId &&
            s.occasionId === occasionId);
        return stats ? stats[metric] : '';
    };

    const GetInstructionTeamStats = (occasionId, instructionId) => {
        let occasionInsructionStats = props.teamStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);

        return occasionInsructionStats ?? null;
    }

    const GetInstructionProfileStats = (occasionId, instructionId) => {
        let occasionInsructionStats = props.profileStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);

        return occasionInsructionStats ?? null;
    }

    const COLORS = [
        '#43658B',
        '#8CBCBA',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];

    const color = (index) => {
        return COLORS[index % COLORS.length];
    }

    // const GetInstructionDataGraph = (instruction) => {
    //     if (instruction.unit === 'FailPass') {
    //         return (
    //             <Polar data={GetInstructionPieData(instruction.id)} options={
    //                 {
    //                     scale: {
    //                         ticks: {
    //                             min: 0,
    //                             max: 100
    //                         }
    //                     }
    //                 }
    //             }></Polar>
    //         );
    //     }
    //     else {
    //         return (
    //             <Line data={GetInstructionLineData(instruction.id)}></Line>
    //         );
    //     }
    // }

    // const GetInstructionPieData = (instructionId) => {

    //     const instructionDatapoints = teamNormalDistributionData.filter(data => data.instructionId === instructionId);

    //     const labels = occasions.map(occasion => occasion.name);

    //     const datasets = [{
    //         data: occasions.map(o => instructionDatapoints.find(d => d.occasionId === o.id && d.valueSection === 1)?.percentOfResults || 0),
    //         backgroundColor: occasions.map(occasion => color(occasion.id)),
    //         fill: false
    //     }];

    //     return {
    //         labels: labels,
    //         datasets: datasets
    //     }
    // }

    // const GetInstructionLineData = (instructionId) => {

    //     const teamInstructionDatapoints = teamNormalDistributionData.filter(data => data.instructionId === instructionId);
    //     const profileInstructionDatapoints = profileNormalDistributionData.filter(data => data.instructionId === instructionId);

    //     const teamValues = teamInstructionDatapoints.map(d => d.valueSection);
    //     const profileValues = profileInstructionDatapoints.map(d => d.valueSection);

    //     const labels = [... new Set([...teamValues, ...profileValues])].sort((a, b) => a - b);
    //     const teamDatasets = occasions.map(occasion => {
    //         return {
    //             label: occasion.name +  ' (team)',
    //             data: labels.map(l => teamInstructionDatapoints.find(d => d.occasionId === occasion.id && d.valueSection === l)?.percentOfResults || 0),
    //             borderColor: color(occasion.id),
    //             fill: false,
    //             tension: 0.4
    //         }
    //     });
    //     const profileDatasets = occasions.map(occasion => {
    //         return {
    //             label: occasion.name +  ' (global)',
    //             data: labels.map(l => profileInstructionDatapoints.find(d => d.occasionId === occasion.id && d.valueSection === l)?.percentOfResults || 0),
    //             borderColor: color(occasion.id),
    //             fill: true,
    //             tension: 0.4,
    //             hidden: true
    //         }
    //     });


    //     return {
    //         labels: labels,
    //         datasets: [...teamDatasets, ...profileDatasets]
    //     }
    // }

    const GetProgressGraph = (instructionId) => {
        const labels = occasions.map(o => o.name);

        const teamValues = occasions.map(o => {
            const teamStat = teamStatistics.find(s => s.instructionId === instructionId && s.occasionId === o.id);
            return teamStat?.average ?? 0;
        })
        const teamDataset = {
            label: "Team",
            data: teamValues,
            backgroundColor: color(0)
        }

        const profileValues = occasions.map(o => {
            const profileStat = profileStatistics.find(s => s.instructionId === instructionId && s.occasionId === o.id);
            return profileStat?.average ?? 0;
        })
        const profileDataset = {
            label: "Global",
            data: profileValues,
            backgroundColor: color(1)
        }

        const data = {
            labels: labels,
            datasets: [teamDataset, profileDataset]
        }

        return (
            <Bar data={data} />
        );
    }

    if (loading) {
        return "Loading...";
    }

    return (
        <div className={classes.root}>
            <Table>
                <TableBody>
                    {instructions.map((instruction) => {
                        return (
                            <Fragment key={instruction.id}>
                                <TableRow>
                                    <TableCell className={classes.instructionNameRow}>{instruction.name}</TableCell>
                                    <TableCell className={classes.instructionNameRow}></TableCell>
                                    <TableCell className={classes.instructionNameRow}></TableCell>
                                    <TableCell className={classes.instructionNameRow}></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        {GetProgressGraph(instruction.id)}
                                    </TableCell>
                                </TableRow>
                                {/* {occasions.map((occasion, occasionIndex) => {
                                    const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];

                                    return (
                                        <TableRow key={occasion.id}>
                                            {occasionIndex === 0 && <TableCell rowSpan={occasions.length}>
                                                {GetProgressGraph(instruction.id)}
                                            </TableCell>}
                                            <TableCell>{occasion.name}</TableCell>
                                            <TableCell>
                                                <InputComponent value={GetProfileStatistic(instruction.id, occasion.id, 'average')}
                                                    excel={true}
                                                    readOnly={true}
                                                    statistic={true}
                                                    statistics={GetInstructionProfileStats(occasion.id, instruction.id)} metric={'average'}
                                                    instruction={instruction} />
                                            </TableCell>
                                            <TableCell>
                                                <InputComponent value={GetTeamStatistic(instruction.id, occasion.id, 'average')}
                                                    excel={true}
                                                    readOnly={true}
                                                    statistic={true}
                                                    statistics={GetInstructionTeamStats(occasion.id, instruction.id)} metric={'average'}
                                                    instruction={instruction} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })} */}
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
export { TeamStatsComparison };