import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f538',
    margin: '.4rem'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const TestStatsCard = props => {

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.instructionData.name}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.instructionData.name}
        subheader=""
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {props.occasions.map((occasion, index) => (
                  <TableCell key={occasion.id}>{occasion.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell>Count</TableCell>
                  {props.occasions.map((occasion, columnIndex) => (
                    <TableCell key={columnIndex}>{props.instructionData.occasionStats.find(o => o.name === occasion.name)["count"].toFixed(2)}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Max</TableCell>
                  {props.occasions.map((occasion, columnIndex) => (
                    <TableCell key={columnIndex}>{props.instructionData.occasionStats.find(o => o.name === occasion.name)["maximum"].toFixed(2)}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Min</TableCell>
                  {props.occasions.map((occasion, columnIndex) => (
                    <TableCell key={columnIndex}>{props.instructionData.occasionStats.find(o => o.name === occasion.name)["minimum"].toFixed(2)}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Average</TableCell>
                  {props.occasions.map((occasion, columnIndex) => (
                    <TableCell key={columnIndex}>{props.instructionData.occasionStats.find(o => o.name === occasion.name)["average"].toFixed(2)}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Standard deviation</TableCell>
                  {props.occasions.map((occasion, columnIndex) => (
                    <TableCell key={columnIndex}>{props.instructionData.occasionStats.find(o => o.name === occasion.name)["standardDeviation"].toFixed(2)}</TableCell>
                  ))}
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
export default TestStatsCard;