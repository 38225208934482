import React, { useState, useContext } from 'react';
// MaterialUI
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// Testelope
import InputComponentsMap from './InputComponentsMap';
import { LanguageContext } from 'context';
import { Line, Pie } from 'react-chartjs-2';
import { renderToString } from 'react-dom/server';

const useStyles = makeStyles(theme => ({
    table: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderTop: '1px solid grey',
    },
    cell: {
        margin: 0,
        border: '1px solid grey',
        whiteSpace: 'nowrap',
        borderTopWidth: '0px',
        height: '22px',
        padding: '3px',
        textAlign: 'center',
        color: '#000',
        fontWeight: 'initial',
        '& input': {
            width: '40px',
            height: '22px',
            textAlign: 'right',
            color: '#000',
            borderWidth: '0px',
            background: 'inherit'
        }
    },
    emptyCell: {
        height: '22px',
    },
    borderBottom: {
        borderBottom: '2px solid grey'
    },
    column0: {
        backgroundColor: '#eee'
    },
    column1: {},
    div: {
        overflowX: 'scroll',
        marginLeft: '12em',
        overflowY: 'visible',
        padding: 0,
    },
    headcol: {
        position: 'absolute',
        marginLeft: '-12em',
        maxWidth: '12em',
        top: 'auto',
        borderTopWidth: '1px',
        /*only relevant for first row*/
        marginRop: '-1px',
        /*compensate for top border*/
        display: 'table-column',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'noWrap'
    },
    lastHeadCol: {
        borderBottom: '2px solid grey'
    },
    notSaved: {
        backgroundColor: "#f99"
    },
    notNormalized: {
        backgroundColor: "#FF0"
    },
    printIcon: {
    },
    defaultCursor: {
        cursor: 'default'
    },
    instructionDiv: {
        pageBreakAfter: 'auto',
        pageBreakBefore: 'auto',
        pageBreakInside: 'avoid',
        display: 'inline-block',
        padding: '5px',
        margin: '5px',
        border: '1px solid grey'
    },
    print:{
        fontSize: 'small',
        padding: '0px',
        '& input': {
            width: '26px',
            fontSize: 'small'
        }
    }
}));

const OverviewTable = props => {

    const classes = useStyles();
    const { strings } = useContext(LanguageContext);
    const [testSearchFilter, setTestSearchFilter] = useState('');
    const [userSearchFilter, setUserSearchFilter] = useState('');
    const [instructionDetailsDialogOpen, setInstructionDetailsDialogOpen] = useState(false);
    const [selectedInstruction, setSelectedInstruction] = useState(null);

    const FilterTests = (tests, filter) => {
        if (tests !== undefined) {
            return tests.filter((t) => {
                return t.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            });
        }
    }
    const FilterUsers = (users, filter) => {
        if (users !== undefined) {
            return users.filter((u) => {
                return (u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            });
        }
    }

    const GetTeamStatsValue = (occasionId, instructionId, propertyName) => {

        let occasionInsructionStats = props.teamStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);
        if (occasionInsructionStats) {
            return occasionInsructionStats[propertyName];
        }

        return "";
    }

    const GetInstructionTeamStats = (occasionId, instructionId) => {
        let occasionInsructionStats = props.teamStatistics.find(s => s.occasionId === occasionId && s.instructionId === instructionId);
        
        return occasionInsructionStats ?? null;
    }

    const GetResult = (userId, occasionId, instructionId) => {
        let value = props.results.find(result => result.userId === userId &&
            result.instructionId === instructionId &&
            result.occasionId === occasionId);

        if (value) {
            return value;
        }
        return null;
    }

    const NumberOfInstructionOccasions = (instructionId) => {
        return props.occasions.filter(o => o.instructionIds.includes(instructionId)).length;
    }

    const InstructionOccasions = (instructionId) => {
        return props.occasions.filter(o => o.instructionIds.includes(instructionId));
    }

    const ShowInstructionDetails = (instruction) => {
        setSelectedInstruction(instruction);
        setInstructionDetailsDialogOpen(true);
    }

    const maxNormalizedValue = results => {
        var max = results.reduce((a, b) => {
            return Math.max(a, b.normalizedValue);
        }, 0);
        return max;
    }
    const minNormalizedValue = results => {
        var min = results.reduce((a, b) => {
            return Math.min(a, b.normalizedValue);
        }, 100000000);
        return min;
    }
    const selectedInstructionResults = () => {
        return props.results.filter(r => r.instructionId === selectedInstruction.id && r.normalizedValue != null);
    }
    var normalDistributionData = () => {
        let labels = [];
        let dataPoints = [];

        const instructionResults = selectedInstructionResults();
        const max = maxNormalizedValue(instructionResults);
        const min = minNormalizedValue(instructionResults);
        const steps = 20;

        var stepSize = (max - min) / steps;

        for (let i = 0; i < steps; i++) {
            const start = min + stepSize * i;
            const end = start + stepSize;
            var resultsInRange = instructionResults.filter((result) => {
                return result.normalizedValue >= start &&
                    result.normalizedValue <= end;
            });

            if (resultsInRange.length > 0) {
                labels.push(start.toFixed(2) + '-' + end.toFixed(2));

                dataPoints.push(Math.round(resultsInRange.length / instructionResults.length * 100));
            }
        }
        return {
            labels: labels,
            datasets: [
                {
                    label: '% of results',
                    fill: false,
                    lineTension: 0.1,
                    // backgroundColor: 'rgba(75,192,192,0.4)',
                    // borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: dataPoints
                }
            ]
        };
    }
    const metricDisplayName = (metric) => {
        if (metric === 'maximum') {
            return `${strings.max} / #${strings.pass}`;
        }
        if (metric === 'minimum') {
            return `${strings.min} / #${strings.fail}`;
        }
        if (metric === 'average') {
            return `${strings.average} / % ${strings.pass}`;
        }
        if (metric === 'median') {
            return strings.median;
        }
    }

    const InstructionResultOverview = () => {
        const results = selectedInstructionResults();
        if (results.length) {
            if (selectedInstruction.unit === 'FailPass') {
                const passCount = results.filter(r => r.normalizedValue === 1).length;
                const failCount = results.filter(r => r.normalizedValue === 0).length;
                const pieData = {
                    labels: ["G", "U"],
                    datasets: [
                        {
                            data: [passCount, failCount],
                            backgroundColor: ['green', 'red']
                        }
                    ]
                };
                return <Pie data={pieData}></Pie>;
            }
            return (<Line data={normalDistributionData()} ></Line>);
        }
    }

    const InstructionTable = (instructionToRender, forPrint) => {
        return (
            <div className={classes.instructionDiv}>
                <h4>{instructionToRender.name}</h4>
                <table className={`${classes.table} ${forPrint && classes.print}`}>
                    <thead>
                        <tr>
                            <td>
                            </td>
                            {InstructionOccasions(instructionToRender.id).map((occasion) => {
                                return <td className={`${classes.cell} ${forPrint && classes.print}`} key={occasion.id}>{occasion.name}</td>
                            })}
                        </tr>
                        {['maximum', 'minimum', 'average'].map(metric => {
                            return (
                                <tr key={metric}>
                                    <td>
                                        {forPrint ? metric : metricDisplayName(metric)} (team)
                                    </td>
                                    {InstructionOccasions(instructionToRender.id).map((occasion) => {
                                        const InputComponent = InputComponentsMap[instructionToRender.unit] || InputComponentsMap["Default"];
                                        const value = GetTeamStatsValue(occasion.id, instructionToRender.id, metric);
                                        return (
                                            <td key={occasion.id} className={`${classes.cell} ${metric === 'average' && classes.lastHeadCol} ${forPrint && classes.print}`}>
                                                <InputComponent value={value}
                                                    excel={true}
                                                    readOnly={true}
                                                    statistic={true}
                                                    statistics={GetInstructionTeamStats(occasion.id, instructionToRender.id)} metric={metric}
                                                    instruction={instructionToRender} />
                                            </td>
                                        );
                                    })}
                                </tr>
                            )
                        })}

                    </thead>
                    <tbody>
                        {props.teamMembers.map((teamMember) => {
                            return (
                                <tr key={teamMember.user.id}>
                                    <td>{teamMember.user.firstName} {teamMember.user.lastName}</td>
                                    {InstructionOccasions(instructionToRender.id).map((occasion, index) => {
                                        const InputComponent = InputComponentsMap[instructionToRender.unit] || InputComponentsMap["Default"];
                                        let result = GetResult(teamMember.user.id, occasion.id, instructionToRender.id);
                                        return (
                                            <td key={index} className={`${classes.cell} ${forPrint && classes.print}`}>
                                                <InputComponent value={result ? result.value : ''}
                                                    normalizedValue={result ? result.normalizedValue : null}
                                                    excel={true}
                                                    readOnly={true}
                                                    instruction={instructionToRender} />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    const printAllInstructions = () => {
        const printContent = props.instructions.map(instruction => {
            return renderToString(InstructionTable(instruction, true));
        }).join('');
        
        let printMe = document.getElementById('printMe');
        printMe.contentWindow.document.open();
        printMe.contentWindow.document.write(printContent);
        printMe.contentWindow.document.close();

        printMe.contentWindow.document.head.innerHTML = document.head.innerHTML;
        printMe.focus();
        printMe.contentWindow.print();
    }

    return (
        <div className={classes.div}>
            <Button onClick={() => printAllInstructions()}>Print all</Button>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.headcol}>
                            <input type="text" placeholder={strings.filterTests} onChange={(e) => setTestSearchFilter(e.target.value)}></input>
                        </th>
                        <th className={classes.emptyCell}></th>
                        {FilterTests(props.instructions, testSearchFilter).map((instruction, index) => (
                            <Tooltip className={classes.defaultCursor} key={instruction.id} title={instruction.description} arrow>
                                <th colSpan={NumberOfInstructionOccasions(instruction.id)}
                                    key={instruction.id} className={classes.cell}
                                    onClick={() => ShowInstructionDetails(instruction)}>
                                    {instruction.name}
                                </th>
                            </Tooltip>
                        ))}
                    </tr>
                    <tr>
                        <th className={classes.headcol}>
                            <input type="text" placeholder={strings.filterPlayers} onChange={(e) => setUserSearchFilter(e.target.value)}></input>
                        </th>
                        <th className={classes.emptyCell}></th>
                        {FilterTests(props.instructions, testSearchFilter).map((instruction, index) => (
                            InstructionOccasions(instruction.id).map(occasion => (
                                <th key={occasion.id} className={classes.cell + " " + classes["column" + (index % 2)]}>{occasion.name}</th>
                            ))
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {['maximum', 'minimum', 'average'].map(metric => {
                        return (
                            <tr key={metric}>
                                <th className={classes.headcol}>{metricDisplayName(metric)}</th>
                                <th className={classes.emptyCell}></th>
                                {FilterTests(props.instructions, testSearchFilter).map((instruction, index) => (
                                    InstructionOccasions(instruction.id).map(occasion => {
                                        const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];
                                        const value = GetTeamStatsValue(occasion.id, instruction.id, metric);
                                        const classNames = `${classes.cell} ${classes["column" + (index % 2)]} ${metric === 'average' && classes.lastHeadCol}`
                                        return (
                                            <th key={instruction.id + ":" + occasion.id} className={classNames}>
                                                <InputComponent value={value}
                                                    excel={true}
                                                    readOnly={true}
                                                    statistic={true}
                                                    statistics={GetInstructionTeamStats(occasion.id, instruction.id)} metric={metric}
                                                    instruction={instruction} />
                                            </th>
                                        );
                                    })
                                ))}
                            </tr>
                        );
                    })}
                    {FilterUsers(props.teamMembers, userSearchFilter).map((teamMember, userIndex) => (
                        <tr key={teamMember.user.id}>
                            <td className={classes.headcol} >
                                <IconButton size='small' className={classes.printIcon} onClick={() => props.showUserOverview(teamMember.user)}><PrintIcon fontSize='inherit'></PrintIcon></IconButton>
                                {teamMember.user.firstName} {teamMember.user.lastName}
                            </td>
                            <td className={classes.emptyCell}></td>
                            {FilterTests(props.instructions, testSearchFilter).map((instruction, index) => (
                                InstructionOccasions(instruction.id).map(occasion => {
                                    const InputComponent = InputComponentsMap[instruction.unit] || InputComponentsMap["Default"];
                                    let result = GetResult(teamMember.user.id, occasion.id, instruction.id);
                                    let notNormalized = result && result.value != null && result.value !== '' && result.normalizedValue == null;
                                    let notNormalizedClass = (notNormalized ? " " + classes.notNormalized : "");
                                    let notSaved = result && result.saved === false;
                                    let notSavedClass = (notSaved ? " " + classes.notSaved : "");

                                    let toolTip = (notNormalized ? strings.noValidValue : "") + (notSaved ? strings.notSaved : "");

                                    if (instruction.valueFormatRegex && instruction.valueFormatMessage && result && result.value) {
                                        if (!RegExp(instruction.valueFormatRegex).test(result.value)) {
                                            toolTip = instruction.valueFormatMessage;
                                        }
                                    }

                                    return (
                                        <Tooltip key={instruction.id + ":" + occasion.id} title={toolTip} arrow>
                                            <td key={instruction.id + ":" + occasion.id}
                                                className={classes.cell + " " + classes["column" + (index % 2)] + notSavedClass + notNormalizedClass} >
                                                <InputComponent value={result ? result.value : ''}
                                                    normalizedValue={result ? result.normalizedValue : null}
                                                    excel={true}
                                                    readOnly={true}
                                                    instruction={instruction} />
                                            </td>
                                        </Tooltip>
                                    );
                                })
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Dialog open={instructionDetailsDialogOpen} maxWidth="lg">
                {instructionDetailsDialogOpen && <>
                    <DialogContent dividers id="InstructionDetails">
                        {selectedInstruction &&
                            <Grid container>
                                <Grid item sm={6}>
                                    {InstructionTable(selectedInstruction)}
                                </Grid>
                                <Grid item sm={6}>
                                    {InstructionResultOverview()}
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={() => props.printElementHtml('InstructionDetails')}>Print</Button> */}
                        <Button onClick={() => setInstructionDetailsDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </>}
            </Dialog>
        </div>
    )
};

export default OverviewTable;