import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { LanguageContext } from 'context';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  loader: {
    paddingTop: '1rem'
  },
  listItem: {
    cursor: 'pointer',
  }
}));

const LatestTeams = props => {
  const { className, latestTeams, ...rest } = props;
  const { strings } = useContext(LanguageContext);
  const classes = useStyles();
  const history = useHistory();

  const viewTeam = (event, id) => {
    event.stopPropagation();
    const path = '/teams/' + id;
    history.push(path);
  }

  if(latestTeams === null || latestTeams === undefined) {
    return (
      <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <CardHeader
        title={strings.latestUpdatedTeams} />
      <Divider />
      <CardContent className={classes.content}>
       <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      </CardContent>
    </Card>
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <CardHeader
        subtitle={`${latestTeams.length} in total`}
        title={strings.latestUpdatedTeams} />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {latestTeams.map((team, i) => (    
            <ListItem
              className={classes.listItem}
              divider={i < latestTeams.length - 1}
              key={team.id}
              onClick={event => viewTeam(event, team.id)} >
              <ListItemText
                primary={team.name}
                secondary={`${strings.updated} ${ moment.tz(team.lastUpdated, moment.tz.guess()).format('LLLL') }`}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={event => viewTeam(event, '#')} >
          {strings.viewAll} <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestTeams.propTypes = {
  className: PropTypes.string
};

export default LatestTeams;
