import React, { useState, createContext, useEffect } from 'react';
import { default as translations } from '../language/translations.js'

export const LanguageContext = createContext({
    userLanguage: 'en',
});

export function LanguageProvider({ children }) {

    const [userLanguage, setUserLanguage] = useState('en');

    let strings = translations
    
    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        const storedLanguage = window.localStorage.getItem('rcml-lang')

        strings.setLanguage(storedLanguage ?? browserLanguage ?? userLanguage);
    }, [strings, userLanguage]);

    const provider = {
        userLanguage,
        strings,
        userLanguageChange: (language) => {
            setUserLanguage(language);
            window.localStorage.setItem('rcml-lang', language);
        }
    };

    return (
    <LanguageContext.Provider value={provider}>
        {children}
    </LanguageContext.Provider>
    );
};